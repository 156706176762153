import { first as _first, isEqual, last } from 'lodash-es';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    Subject,
    catchError,
    combineLatest,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    first,
    map,
    of,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs';

import { animate, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { translate } from '@jsverse/transloco';
import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';
import { getDerivativeStaticInfo } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import { CategoryItem } from '@mhp/aml-ui-shared-services/configuration/categories';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    EASE_OUT_EXPO,
    TRACK_BY_ID,
    UiBaseComponent
} from '@mhp/ui-components';
import {
    ApplicationStateService,
    ErrorHandlerService
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../../environments/environment';
import { SearchState } from '../../../common/search/models/search.model';
import { toggleMenuActive } from '../../../menu/state/actions/common-menu.actions';
import { LocalApplicationState } from '../../../state/local-application-state.interface';
import { CameraControlService } from '../../camera-control/camera-control.service';
import {
    ExtendedUiBeautyshotAwareConfigurationMetaItem,
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    ExtendedUiOptionGroup
} from '../../configuration-model/configuration-interfaces';
import { SearchService } from '../../search/search.service';
import {
    isBeautyshotAware,
    isCodeAware,
    isContentAware,
    isExtendedUiOptionCode,
    isExtendedUiOptionCollection
} from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../services/product-configuration-session.service';
import {
    exitSearchState,
    setActiveSearchTerm,
    setFocusTargetNode,
    setSearchInputActive,
    setStageMinimizedState
} from '../../state/actions/configuration.actions';
import {
    selectActiveConfigurationSearchTerm,
    selectFocusTargetNode,
    selectSearchInputActive,
    selectShowSearchResults
} from '../../state/selectors/configuration.selectors';
import { VrInfoService } from '../../vr/vr-info.service';
import { CLEAR_FOCUS_TARGET_NODE_STATE_DELAY_TIME } from '../configuration-bar/configuration-bar.constants';
import { EditionsService } from '../editions/editions.service';
import { EnvironmentSelectionDialogService } from '../environment-selection/environment-selection-dialog.service';
import {
    combineCategorySourceItemFilters,
    topLevelCategoryFilter
} from '../helpers/category-filters';
import { CategoryMapper } from '../helpers/category-mapper';
import { CategoryMapperService } from '../helpers/category-mapper.service';
import { CATEGORY_DESCRIPTION_REMOVAL_MODIFIER } from '../helpers/category-modifiers';
import { DisplayedOptionsService } from '../helpers/displayed-options.service';
import { RenderType } from './configuration-group-first-level/configuration-group-first-level.component';

const ENABLE_BEAUTYSHOT_ON_STUCK_DELAY = 300;
const IDENTIFIER_SUMMARY =
    environment.appConfig.configuration.identifierSummary;
const IDENTIFIER_PERSONALISATION =
    environment.appConfig.configuration.identifierPersonalisation;
const IDENTIFIER_ACCESSORIES =
    environment.appConfig.configuration.identifierAccessories;
const IDENTIFIER_INTERIOR_WITH_ENVIRONMENT_SELECTION =
    environment.appConfig.configuration
        .identifierInteriorWithEnvironmentSelection;
const IDENTIFIER_ENVIRONMENT_SELECTION =
    environment.appConfig.configuration.identifierEnvironmentSelection;
const IDENTIFIER_STITCHING_TYPE_SELECTION: Set<string> = new Set<string>(
    environment.appConfig.configuration.identifierStitchingTypeSelection
);
const IDENTIFIER_STITCHING_LIST_CODES: Set<string> = new Set<string>(
    environment.appConfig.configuration.identifierStitchingListCodes
);

@UntilDestroy()
@Component({
    selector: 'mhp-configuration-bar-compact',
    templateUrl: './configuration-bar-compact.component.html',
    styleUrls: ['./configuration-bar-compact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('toggle', [
            transition('void => *', [
                style({
                    height: '0'
                }),
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        height: '*'
                    })
                )
            ]),
            transition('* => void', [
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        height: 0
                    })
                )
            ])
        ])
    ]
})
export class ConfigurationBarCompactComponent
    extends UiBaseComponent
    implements OnInit
{
    readonly IDENTIFIER_SUMMARY =
        environment.appConfig.configuration.identifierSummary;

    readonly CATEGORY_EDITIONS =
        environment.appConfig.configuration.identifierRootLevelEditions;

    @Input()
    canSelectPrev: boolean;

    @Input()
    canSelectNext: boolean;

    @Output()
    readonly selectedTopLevelAreaChange = new EventEmitter<
        string | 'SUMMARY'
    >();

    @Output()
    readonly selectedCategoryPathChange = new EventEmitter<
        string[] | undefined
    >();

    @ViewChild('configurationAreaContainer')
    configurationAreaContainerRef: ElementRef;

    readonly updatingOptionSelection = toSignal(
        this.productConfigurationSessionService.isSelectionChangeActive$(),
        {
            initialValue: false
        }
    );

    private readonly activeTopLevelCategorySubject = new BehaviorSubject<
        CategoryItem | undefined
    >(undefined);

    private readonly expansionStateSubject = new BehaviorSubject<Set<string>>(
        new Set()
    );

    private readonly scrollIntoViewSubject = new Subject<string>();

    private readonly focusBeautyshotForItem = new Subject<string>();

    private readonly categoryItemMapper: CategoryMapper;

    readonly trackById = TRACK_BY_ID;

    searchState: SearchState = {
        exitSearchState,
        selectActiveConfigurationSearchTerm,
        selectSearchInputActive,
        setActiveSearchTerm,
        setSearchInputActive
    };

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly categoryMapperService: CategoryMapperService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly searchService: SearchService,
        private readonly cameraControlService: CameraControlService,
        private readonly displayedOptionsService: DisplayedOptionsService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly environmentSelectionDialogService: EnvironmentSelectionDialogService,
        private readonly editionsService: EditionsService,
        private readonly vrInfoService: VrInfoService
    ) {
        super();

        this.categoryItemMapper =
            this.categoryMapperService.getCategoryItemMapper();

        this.initExpandFirstGroupIfNoneExpanded();
        this.initFocusTargetNodeLogic();
        this.initApplyStageMinimizeStateOnCategoryChangeLogic();
        this.initEnableBeautyshotWhenStuckBinding();
        this.initEmitActiveTopLevelAreaChangeBinding();

        this.completeOnDestroy(
            this.activeTopLevelCategorySubject,
            this.expansionStateSubject,
            this.scrollIntoViewSubject,
            this.focusBeautyshotForItem,
            this.selectedCategoryPathChange,
            this.selectedTopLevelAreaChange
        );
    }

    ngOnInit() {
        // select first group
        this.getRootLevelGroups$()
            .pipe(
                filter((groups) => !!groups),
                first()
            )
            .subscribe(() => {
                this.intentGoToNext();
            });
    }

    intentGoToPrev() {
        this.getAllAndActiveTopLevelItem$()
            .pipe(
                first(),
                map(([all, activeItem]) => {
                    const activeInList = all.find(
                        (item) => item.id === activeItem?.id
                    );
                    if (!activeInList) {
                        return _first(all);
                    }
                    const currentIndex = all.indexOf(activeInList);
                    if (currentIndex <= 0) {
                        return activeInList;
                    }
                    return all[currentIndex - 1];
                })
            )
            .subscribe((prevItem) => {
                if (prevItem) {
                    this.applicationStateService.dispatch(
                        setFocusTargetNode({
                            id: prevItem.id
                        })
                    );
                }
            });
    }

    intentGoToNext() {
        this.getNextTopLevelItem$()
            .pipe(first())
            .subscribe((nextItem) => {
                if (nextItem) {
                    this.applicationStateService.dispatch(
                        setFocusTargetNode({
                            id: nextItem.id
                        })
                    );
                }
            });
    }

    intentGoToSummary() {
        this.applicationStateService.dispatch(
            setFocusTargetNode({
                id: IDENTIFIER_SUMMARY
            })
        );
    }

    intentToggleMenu() {
        this.applicationStateService.dispatch(toggleMenuActive());
    }

    intentShowEnvironmentLayer() {
        this.environmentSelectionDialogService
            .showEnvironmentSelectionDialog$(
                this.viewContainerRef,
                this.componentFactoryResolver
            )
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }

    /**
     * Show the editions-info-dialog to the user.
     */
    intentShowEditionsInfo() {
        this.editionsService
            .intentShowEditionsInfo$(undefined, {
                forceShowDialog: true
            })
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    intentExpandGroup(
        firstLevelItem: Identifiable,
        expanded: boolean,
        scrollIntoView?: boolean
    ) {
        if (expanded) {
            this.expansionStateSubject.value.add(firstLevelItem.id);
            this.handleContentAwareGotFocus(firstLevelItem.id);
        } else {
            this.expansionStateSubject.value.delete(firstLevelItem.id);
        }
        this.expansionStateSubject.next(this.expansionStateSubject.value);

        if (scrollIntoView) {
            setTimeout(() => {
                this.scrollIntoViewSubject.next(firstLevelItem.id);
            });
        }
    }

    intentSelectItem(itemId: string) {
        this.productConfigurationSessionService
            .getOptionGroups$()
            .pipe(
                take(1),
                switchMap((optionGroups) => {
                    if (!optionGroups) {
                        return EMPTY;
                    }
                    const editionNode =
                        this.editionsService.getEditionNodeOtherThanNoPack(
                            itemId,
                            optionGroups
                        );
                    if (editionNode) {
                        return this.editionsService.intentShowEditionsInfo$(
                            editionNode
                        );
                    }

                    return this.productConfigurationSessionService.updateNodeSelection(
                        itemId
                    );
                })
            )
            .subscribe();
    }

    intentExitSearch() {
        this.applicationStateService.dispatch(exitSearchState());
    }

    onItemHeaderStuck(
        groupOrCollection: ExtendedUiContentAwareConfigurationMetaItem
    ) {
        this.handleContentAwareGotFocus(groupOrCollection.id);
    }

    getTopLevelCategories$(): Observable<CategoryItem[]> {
        return this.categoryMapperService.getCategoryItems$(
            this.getRootLevelGroups$(),
            combineCategorySourceItemFilters(
                (item, path) => path.length === 0,
                topLevelCategoryFilter
            ),
            CATEGORY_DESCRIPTION_REMOVAL_MODIFIER
        );
    }

    @MemoizeObservable()
    getActiveTopLevelItem$(): Observable<CategoryItem | undefined> {
        return this.activeTopLevelCategorySubject.asObservable();
    }

    @MemoizeObservable()
    getNextTopLevelItem$(): Observable<CategoryItem | undefined> {
        return this.getAllAndActiveTopLevelItem$().pipe(
            map(([all, activeItem]) => {
                const activeInList = all.find(
                    (item) => item.id === activeItem?.id
                );
                if (!activeInList) {
                    return _first(all);
                }
                const currentIndex = all.indexOf(activeInList);
                if (currentIndex === all.length - 1) {
                    return undefined;
                }
                return all[currentIndex + 1];
            })
        );
    }

    @MemoizeObservable()
    getNextTopLevelItemExcludingSummary$(): Observable<
        CategoryItem | undefined
    > {
        return this.getNextTopLevelItem$().pipe(
            map((item) => {
                if (item?.id === IDENTIFIER_SUMMARY) {
                    return undefined;
                }
                return item;
            })
        );
    }

    @MemoizeObservable()
    getSummaryTopLevelItem$() {
        return this.getTopLevelCategories$().pipe(
            map((categories) =>
                categories.find((item) => item.id === IDENTIFIER_SUMMARY)
            )
        );
    }

    @MemoizeObservable()
    getRootLevelGroups$(): Observable<ExtendedUiOptionGroup[] | undefined> {
        return this.productConfigurationSessionService.getOptionGroups$();
    }

    @MemoizeObservable()
    getFilteredRootLevelGroups$(): Observable<
        ExtendedUiOptionGroup[] | undefined
    > {
        return this.searchService
            .getFilteredOptions$(this.getRootLevelGroups$())
            .pipe(lazyShareReplay());
    }

    @MemoizeObservable()
    getFirstLevelContentAwares$(): Observable<
        ExtendedUiContentAwareConfigurationMetaItem[] | undefined
    > {
        return this.getActiveTopLevelItem$().pipe(
            switchMap((activeItem) => {
                if (!activeItem || activeItem.id === IDENTIFIER_SUMMARY) {
                    return of(undefined);
                }
                return this.getFilteredRootLevelGroups$().pipe(
                    map((rootLevelGroups) => {
                        const matchingGroup = rootLevelGroups?.find(
                            (group) => group.id === activeItem.id
                        );
                        if (!matchingGroup) {
                            return undefined;
                        }

                        const firstLevelGroupFilter = (item) =>
                            isContentAware(item) &&
                            item.name !== IDENTIFIER_ENVIRONMENT_SELECTION &&
                            !IDENTIFIER_STITCHING_TYPE_SELECTION.has(
                                item.name ?? ''
                            );
                        return <ExtendedUiContentAwareConfigurationMetaItem[]>(
                            matchingGroup.content.filter(firstLevelGroupFilter)
                        );
                    })
                );
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getInteriorEnvironmentSelector$(): Observable<CategoryItem | undefined> {
        return this.getActiveTopLevelItem$().pipe(
            switchMap((activeItem) => {
                if (
                    !activeItem ||
                    activeItem.nameInternal !==
                        IDENTIFIER_INTERIOR_WITH_ENVIRONMENT_SELECTION
                ) {
                    return of(undefined);
                }

                return this.getAsDropdownCategory$(
                    new Set<string>([IDENTIFIER_ENVIRONMENT_SELECTION])
                );
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getInteriorEnvironmentSelectedId$(): Observable<string | undefined> {
        return this.getInteriorEnvironmentSelector$().pipe(
            map((environmentSelect) => {
                if (!environmentSelect) {
                    return undefined;
                }
                return environmentSelect.children?.find((item) => item.selected)
                    ?.id;
            })
        );
    }

    hasInteriorStitchingSelector(
        item: ExtendedUiContentAwareConfigurationMetaItem
    ) {
        if (!isCodeAware(item)) {
            return false;
        }
        return IDENTIFIER_STITCHING_LIST_CODES.has(item.code);
    }

    @MemoizeObservable()
    getInteriorStitchingSelector$(): Observable<CategoryItem | undefined> {
        return this.getAsDropdownCategory$(
            IDENTIFIER_STITCHING_TYPE_SELECTION
        ).pipe(lazyShareReplay());
    }

    @MemoizeObservable()
    getExpansionState$() {
        return this.expansionStateSubject.asObservable();
    }

    @MemoizeObservable()
    hasNextTopLevelItem$() {
        return this.getAllAndActiveTopLevelItem$().pipe(
            map(
                ([allItems, activeItem]) =>
                    last(allItems)?.id !== activeItem?.id
            )
        );
    }

    @MemoizeObservable()
    hasPrevTopLevelItem$() {
        return this.getAllAndActiveTopLevelItem$().pipe(
            map(
                ([allItems, activeItem]) =>
                    _first(allItems)?.id !== activeItem?.id
            )
        );
    }

    @MemoizeObservable()
    isDisplayingSearchResults$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectShowSearchResults);
    }

    @MemoizeObservable()
    isSearchInputActive$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectSearchInputActive);
    }

    @MemoizeObservable()
    isInteriorEnvironmentSelectionEnabled$() {
        return this.productConfigurationSessionService
            .getConfigurationInfo$()
            .pipe(
                map((info) => info?.productId),
                map((productId) =>
                    productId
                        ? !getDerivativeStaticInfo(productId)
                              .disableInteriorEnvironmentSelection
                        : false
                )
            );
    }

    /**
     * Returns information about the selection state of an edition-option (sub-option of
     * the EDITIONS (Group) > EDITIONS (Collection) hierarchy.
     */
    @MemoizeObservable()
    isEditionActive$(): Observable<boolean> {
        return this.editionsService.isEditionActive$();
    }

    @MemoizeObservable()
    getActiveSearchTerm$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveConfigurationSearchTerm);
    }

    @MemoizeObservable()
    getTotalSearchResultCount$(): Observable<number> {
        return this.displayedOptionsService
            .getOptionsContainedInBranch$(
                of(undefined),
                this.getFilteredRootLevelGroups$()
            )
            .pipe(map((options) => options.length));
    }

    @MemoizeObservable()
    getActiveTopLevelSearchResultCount$(): Observable<number | undefined> {
        return this.getActiveTopLevelItem$().pipe(
            switchMap((topLevelItem) => {
                if (!topLevelItem || topLevelItem.id === IDENTIFIER_SUMMARY) {
                    return of(undefined);
                }
                return this.getFilteredRootLevelGroups$().pipe(
                    map((rootLevelGroups) =>
                        rootLevelGroups?.find(
                            (group) => group.id === topLevelItem.id
                        )
                    ),
                    switchMap((activeGroup) => {
                        if (!activeGroup) {
                            return of(0);
                        }

                        return this.displayedOptionsService
                            .getOptionsContainedInBranch$(
                                of(activeGroup.id),
                                of([activeGroup])
                            )
                            .pipe(map((options) => options.length));
                    })
                );
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getTopLevelCategoryRenderType$() {
        return this.getActiveTopLevelItem$().pipe(
            map((activeTopLevelItem) => {
                if (!activeTopLevelItem) {
                    return undefined;
                }

                const activeTopLevelItemName =
                    activeTopLevelItem.nameInternal.toUpperCase();
                return activeTopLevelItemName ===
                    environment.appConfig.configuration
                        .identifierPersonalisation ||
                    activeTopLevelItemName ===
                        environment.appConfig.configuration
                            .identifierAccessories
                    ? RenderType.COMPOSITE_ITEM
                    : RenderType.REGULAR;
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getScrollIntoViewEmitter$(): Observable<string> {
        return this.scrollIntoViewSubject.asObservable();
    }

    private getAllAndActiveTopLevelItem$(): Observable<
        [CategoryItem[], CategoryItem | undefined]
    > {
        return combineLatest([
            this.getTopLevelCategories$(),
            this.getActiveTopLevelItem$()
        ]);
    }

    private handleContentAwareGotFocus(id: string) {
        this.focusBeautyshotForItem.next(id);

        this.getRootLevelGroups$()
            .pipe(first())
            .subscribe((rootLevelGroups) => {
                if (!rootLevelGroups) {
                    return;
                }
                const pathToNode = this.nodeLookupService
                    .getNodePathToNode(
                        (node) => node.id === id,
                        rootLevelGroups
                    )
                    ?.reverse()
                    ?.map((item) => item.id);
                this.selectedCategoryPathChange.emit(pathToNode);
            });
    }

    private getAsDropdownCategory$(nodeNameCandidates: Set<string>) {
        return this.getFilteredRootLevelGroups$().pipe(
            map((rootLevelGroups): CategoryItem | undefined => {
                if (!rootLevelGroups) {
                    return undefined;
                }

                const dropdownSelectionCollection =
                    this.nodeLookupService.findNode(
                        (node) => nodeNameCandidates.has(node.name ?? ''),
                        rootLevelGroups
                    )?.node;
                if (
                    !dropdownSelectionCollection ||
                    !isExtendedUiOptionCollection(dropdownSelectionCollection)
                ) {
                    return undefined;
                }

                const entries = dropdownSelectionCollection.content
                    .filter((child): child is ExtendedUiOptionCode =>
                        isExtendedUiOptionCode(child)
                    )
                    .map(
                        (optionCodeChild): CategoryItem => ({
                            id: optionCodeChild.id,
                            nameInternal: optionCodeChild.name ?? '',
                            label: optionCodeChild.nameTranslated,
                            description: optionCodeChild.descriptionTranslated,
                            isCategorySelector: false,
                            selected: optionCodeChild.selected,
                            disabled: optionCodeChild.active
                        })
                    );

                return {
                    id: dropdownSelectionCollection.id,
                    nameInternal: dropdownSelectionCollection.name ?? '',
                    label: dropdownSelectionCollection.nameTranslated,
                    description:
                        dropdownSelectionCollection.descriptionTranslated,
                    renderHint: 'dropdown',
                    isCategorySelector: false,
                    children: entries,
                    hasChangeableSelectionInHierarchy:
                        this.categoryItemMapper.hasChangeableSelectionInHierarchy(
                            dropdownSelectionCollection
                        )
                };
            })
        );
    }

    private initExpandFirstGroupIfNoneExpanded() {
        this.getFirstLevelContentAwares$()
            .pipe(
                withLatestFrom(this.expansionStateSubject),
                map(([firstLevelGroups, expansionState]) => {
                    if (!firstLevelGroups) {
                        return undefined;
                    }
                    const expandedGroup = firstLevelGroups.find((group) =>
                        expansionState.has(group.id)
                    );
                    if (!expandedGroup) {
                        return _first(firstLevelGroups);
                    }
                    return undefined;
                }),
                distinctUntilChanged(isEqual),
                this.takeUntilDestroy()
            )
            .subscribe((groupToExpand) => {
                if (!groupToExpand) {
                    return;
                }
                this.intentExpandGroup(groupToExpand, true);
            });
    }

    private initFocusTargetNodeLogic() {
        this.applicationStateService
            .getLocalState()
            .pipe(
                selectFocusTargetNode,
                filter(
                    (
                        targetNodeId
                    ): targetNodeId is {
                        id: string;
                        skipCameraChange?: boolean;
                    } => !!targetNodeId
                ),
                withLatestFrom(
                    this.getRootLevelGroups$(),
                    this.getTopLevelCategories$()
                ),
                map(([targetNodeDetails, rootLevelGroups, topLevelItems]) => {
                    if (!rootLevelGroups) {
                        return undefined;
                    }

                    let topLevelItemToSelect;

                    if (targetNodeDetails === IDENTIFIER_SUMMARY) {
                        topLevelItemToSelect = topLevelItems.find(
                            (item) => item.id === targetNodeDetails.id
                        );

                        if (!topLevelItemToSelect) {
                            return undefined;
                        }
                        this.activeTopLevelCategorySubject.next(
                            topLevelItemToSelect
                        );

                        // scroll configurationAreaContainer to top
                        this.configurationAreaContainerRef.nativeElement.scrollTo(
                            {
                                top: 0,
                                behavior: 'smooth'
                            }
                        );

                        return undefined;
                    }
                    const pathToTargetNode = this.nodeLookupService
                        .getNodePathToNode(
                            (node) => node.id === targetNodeDetails.id,
                            rootLevelGroups
                        )
                        ?.reverse();

                    if (!pathToTargetNode) {
                        return undefined;
                    }
                    const topLevelItemIdToSelect = pathToTargetNode[0]?.id;
                    topLevelItemToSelect = topLevelItems.find(
                        (item) => item.id === topLevelItemIdToSelect
                    );

                    if (!topLevelItemToSelect) {
                        return undefined;
                    }
                    this.activeTopLevelCategorySubject.next(
                        topLevelItemToSelect
                    );

                    return pathToTargetNode[1];
                }),
                delay(0),
                tap((nodeToExpand) => {
                    if (!nodeToExpand) {
                        return;
                    }
                    this.intentExpandGroup(nodeToExpand, true, true);
                }),
                delay(CLEAR_FOCUS_TARGET_NODE_STATE_DELAY_TIME),
                tap(() => {
                    // clear the target node
                    this.applicationStateService.dispatch(
                        setFocusTargetNode({
                            id: undefined
                        })
                    );
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    private initApplyStageMinimizeStateOnCategoryChangeLogic() {
        this.getActiveTopLevelItem$()
            .pipe(
                tap((activeItem) => {
                    const targetMinimizedState =
                        activeItem?.id === IDENTIFIER_SUMMARY ||
                        activeItem?.nameInternal ===
                            IDENTIFIER_PERSONALISATION ||
                        activeItem?.nameInternal === IDENTIFIER_ACCESSORIES;
                    this.applicationStateService.dispatch(
                        setStageMinimizedState({
                            minimized: targetMinimizedState
                        })
                    );
                }),
                this.takeUntilDestroy()
            )
            .subscribe({
                complete: () => {
                    this.applicationStateService.dispatch(
                        setStageMinimizedState({
                            minimized: false
                        })
                    );
                }
            });
    }

    private initEnableBeautyshotWhenStuckBinding() {
        this.focusBeautyshotForItem
            .pipe(
                distinctUntilChanged(),
                debounceTime(ENABLE_BEAUTYSHOT_ON_STUCK_DELAY),
                withLatestFrom(
                    this.getRootLevelGroups$(),
                    this.vrInfoService.isVrActive$(),
                    this.applicationStateService
                        .getLocalState()
                        .pipe(selectFocusTargetNode)
                ),
                map(
                    ([
                        itemToShowBeautyshotFor,
                        rootLevelGroups,
                        isVrActive,
                        activeFocusTargetNode
                    ]) => {
                        if (
                            !rootLevelGroups ||
                            isVrActive ||
                            activeFocusTargetNode?.skipCameraChange
                        ) {
                            // in case no options are available or vr is active or a focus-target-node action is active where no cameras should be changed, actually do nothing
                            return undefined;
                        }

                        const nodePath =
                            this.nodeLookupService.getNodePathToNode(
                                (node) => node.id === itemToShowBeautyshotFor,
                                rootLevelGroups
                            );

                        return nodePath?.find(
                            (
                                node
                            ): node is ExtendedUiBeautyshotAwareConfigurationMetaItem =>
                                isBeautyshotAware(node)
                        )?.beautyshot?.cameraId;
                    }
                ),
                filter((cameraId): cameraId is string => !!cameraId),
                distinctUntilChanged(),
                switchMap((cameraId) =>
                    this.cameraControlService
                        .setActiveCamera$(cameraId, {
                            skipIfUnchanged: true,
                            revertToDefaultCameraIfNonExisting: true
                        })
                        .pipe(
                            this.errorHandlerService.applyRetryWithHintsOnError(
                                () =>
                                    translate(
                                        'CONFIGURATOR.FEATURES.CAMERAS.ERRORS.SET_CAMERA'
                                    )
                            ),
                            catchError(() => EMPTY)
                        )
                ),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    private initEmitActiveTopLevelAreaChangeBinding() {
        this.activeTopLevelCategorySubject
            .pipe(map((topLevelCategory) => topLevelCategory?.nameInternal))
            .subscribe(this.selectedTopLevelAreaChange);
    }
}
