import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src';
import { ConfigOption } from '@mhp-immersive-exp/contracts/src/configuration/config-option.interface';
import { StreamSessionInfo } from '@mhp/ui-shared-services';
import { createAction, props } from '@ngrx/store';

import {
    ConfigurationNavigationArea,
    EnvironmentInfoShownState,
    VisualizationMode
} from '../local-configuration-state.interface';

/**
 * Set the active navigation area in the configuration area.
 */
export const setActiveNavigationArea = createAction(
    '[LOCAL-STATE]: set active navigation area',
    props<{ id: ConfigurationNavigationArea | undefined }>()
);

/**
 * Set the currently locally active configuration-options.
 */
export const setActiveConfigurationOptions = createAction(
    '[LOCAL-STATE]: set active configuration options',
    props<{ options: (string | ConfigOption)[] | undefined }>()
);

/**
 * Set the active searchTerm.
 */
export const setActiveSearchTerm = createAction(
    '[LOCAL-STATE]: set active search term',
    props<{ searchTerm: string | undefined }>()
);

/**
 * Set the search active state.
 */
export const setSearchInputActive = createAction(
    '[LOCAL-STATE]: setSearchInputActive',
    props<{ searchInputActive: boolean }>()
);

/**
 * Exit and clear search state.
 */
export const exitSearchState = createAction('[LOCAL-STATE]: exitSearchState');

/**
 * Set the target visualization mode.
 */
export const setTargetVisualizationMode = createAction(
    '[LOCAL-STATE]: setTargetVisualizationMode',
    props<{ mode: VisualizationMode }>()
);

/**
 * Set the active visualization mode.
 */
export const setActiveVisualizationMode = createAction(
    '[LOCAL-STATE]: setActiveVisualizationMode',
    props<{ mode: VisualizationMode }>()
);

/**
 * Set the candidate visualization mode.
 */
export const setCandidateVisualizationMode = createAction(
    '[LOCAL-STATE]: setCandidateVisualizationMode',
    props<{ mode: VisualizationMode | undefined }>()
);

/**
 * Set the acknowledgement for the active candidateVisualizationMode.
 */
export const setCandidateVisualizationModeAck = createAction(
    '[LOCAL-STATE]: candidateVisualizationModeAck',
    props<{ ack: boolean | undefined }>()
);

/**
 * Set whether the application should try to transition silently to the target visualization mode.
 */
export const setTransitionSilentlyToTargetVisualizationMode = createAction(
    '[LOCAL-STATE]: setTransitionSilentlyToTargetVisualizationMode',
    props<{ transitionSilently: boolean }>()
);

/**
 * Set the stream-session info
 */
export const setActiveStreamSessionInfo = createAction(
    '[LOCAL-STATE]: setActiveStreamSessionInfo',
    props<{ streamSessionInfo: StreamSessionInfo | undefined }>()
);

/**
 * Set the stage shrinked state.
 */
export const setStageShrinkedState = createAction(
    '[LOCAL-STATE]: setStageShrinkedState',
    props<{ shrinked: boolean }>()
);

/**
 * Set the stage shrinked state.
 */
export const setStageMinimizedState = createAction(
    '[LOCAL-STATE]: setStageMinimizedState',
    props<{ minimized: boolean }>()
);

/**
 * Set the currently active model id, e.g. AM6, AM8.
 */
export const setActiveModelId = createAction(
    '[LOCAL-STATE]: setActiveModelId',
    props<{ modelId: string | undefined }>()
);

/**
 * Set the currently active category selection, an array containing ids of
 * selected category-items for the horizontal config-bar.
 */
export const setActiveCategorySelection = createAction(
    '[LOCAL-STATE]: setActiveCategorySelection',
    props<{ selection: string[] }>()
);

/**
 * Set the node that should get focus.
 */
export const setFocusTargetNode = createAction(
    '[LOCAL-STATE]: setFocusTargetNode',
    props<{
        id: string | undefined;
        // should we skip adjusting the camera based on the category-switch?
        skipCameraChange?: boolean;
    }>()
);

/**
 * Set the state for the initial summary visit to only open overlay on the first visit.
 */
export const setInitialSummaryVisit = createAction(
    '[LOCAL-STATE]: setInitialSummaryVisit',
    props<{ initialSummaryVisit: boolean }>()
);

/**
 * Set the state if the user did toggle the roof of a model.
 */
export const setUserToggledRoof = createAction(
    '[LOCAL-STATE]: setUserToggledRoof',
    props<{ userToggledRoof: boolean }>()
);

/**
 * Set the state for the navigation when loading a configuration code
 */
export const setNavigationToSavedConfigurationActive = createAction(
    '[LOCAL-STATE]: setNavigationToSavedConfigurationActive',
    props<{ navigationToSavedConfigurationActive: boolean }>()
);

/**
 * Set the state for signaling that there is a session data update in progress.
 */
export const setUpdateSessionDataInProgress = createAction(
    '[LOCAL-STATE]: setUpdateSessionDataInProgress',
    props<{ updateSessionDataInProgress: boolean }>()
);

/**
 * Update the state for signaling that the environment info for an environment has been shown.
 */
export const updateEnvironmentInfoShown = createAction(
    '[LOCAL-STATE]: updateEnvironmentInfoShown',
    props<EnvironmentInfoShownState>()
);

/**
 * Set the state for signaling that the environment infos have been shown.
 */
export const setEnvironmentInfoShown = createAction(
    '[LOCAL-STATE]: setEnvironmentInfoShown',
    props<{
        state: EnvironmentInfoShownState[];
    }>()
);

/**
 * Set the state for signaling that the editions info has been shown.
 */
export const setEditionsInfoShown = createAction(
    '[LOCAL-STATE]: setEditionsInfoShown',
    props<{
        state: boolean;
    }>()
);

/**
 * Set the state for signaling that the editions info has been shown.
 */
export const setUserEnvironmentLightingStateChoice = createAction(
    '[LOCAL-STATE]: setUserEnvironmentLightingStateChoice',
    props<{
        state: EnvironmentLightingProfileState | undefined;
    }>()
);
