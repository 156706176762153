import { Observable } from 'rxjs';

import { InjectionToken } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { ScreenshotOptions } from '@mhp-immersive-exp/contracts/src/screenshot';
import { ConfigurationState } from '@mhp/communication-models';

import { StrategyProvider } from '../strategy';

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
export interface EngineControlState extends ConfigurationState {}

export interface ProductConfigurationInfo {
    productId: string;
    country?: string;
    options: ConfigModel[];
}

/**
 * Info about a source for a screenshot.
 */
export interface ScreenshotSource {
    filename?: string;
    mimeType: string;
    source: string | Blob;
}

/**
 * Info about a taken screenshot.
 */
export interface ScreenshotInfo {
    filename: string;
    mimeType: string;
    sourceUrl?: string;
    blob?: Blob;
}

/**
 * Info about a captured cinematic.
 */
export interface CinematicCaptureInfo {
    fileId: string;
    url: string;
}

/**
 * Defines the communication-interface to trigger certain actions that are dependent on the backend the application
 * is currently talking to.
 */
export interface EngineControlStrategy {
    /**
     * Apply the given state in one go.
     * @param engineControlState The state to apply.
     */
    applyEngineControlState$(
        engineControlState: EngineControlState
    ): Observable<void>;

    /*
     * Configuration data
     */
    setProductConfiguration$(
        configurationInfo: ProductConfigurationInfo
    ): Observable<ProductConfigurationInfo>;

    /*
     * Environments
     */

    /**
     * Switch to the given environment.
     * @param id The environments id
     * @param environmentState Optional. The environmentState to be set along with the given environment-id. If omitted, state stays as-is.
     * @return Observable stream emitting the environment-id that has been activated and completes afterwards.
     */
    setActiveEnvironmentId$(
        id: string,
        environmentState?: EnvironmentLightingProfileState
    ): Observable<string>;

    /**
     * Set active environment mode to either day or night
     * @return Observable stream emitting the environment-mode that has been activated and completes afterwards.
     */
    setEnvironmentState$(
        state: EnvironmentLightingProfileState
    ): Observable<EnvironmentLightingProfileState | undefined>;

    /*
     * Cameras / Beautyshots
     */

    /**
     * Set the active camera.
     * @param cameraId
     * @return Observable stream emitting the camera-id that has been activated and completes afterwards.
     */
    setActiveCamera$(cameraId: string): Observable<string>;

    /**
     * Take a screenshot of the currently visible rendering and return the URL from which to fetch the image.
     * @param options Options for the screenshot request
     * @return Observable stream emitting the source for the shot and completes afterwards.
     */
    takeScreenshot$(options?: ScreenshotOptions): Observable<ScreenshotSource>;

    /*
     * Animations
     */

    /**
     * Set the animation state for a given animation.
     * @param animationId The animation for which the state should be set.
     * @param direction The direction to which to animation should head towards.
     * @return Observable stream emitting the animationId and completes afterwards.
     */
    setAnimationState$(
        animationId: string,
        direction: 'START' | 'END'
    ): Observable<string>;

    /*
     * Highlights
     */

    /**
     * Toggle the playback-state of a given highlight.
     * @param highlightId The highlight for which playback-state should be toggled
     * @param state The target state for the highlight
     * @return Observable stream emitting the highlightId and completes afterwards.
     */
    setHighlightState$(
        highlightId: string,
        state: 'ACTIVE' | 'INACTIVE'
    ): Observable<string>;

    /**
     * Stop the playback-state of any possibly playing highlights.
     * @return Observable stream emitting nothing and completes in case of success.
     */
    stopHighlight$(): Observable<void>;

    /*
     * Cinematics
     */

    /**
     * Toggle the playback-state of a given cinematic.
     * @param cinematicId The cinematic for which playback-state should be toggled
     * @param state The target state for the cinematic
     * @return Observable stream emitting the cinematicId and completes afterwards.
     */
    setCinematicState$(
        cinematicId: string,
        state: 'ACTIVE' | 'INACTIVE'
    ): Observable<string>;

    /**
     * Stop the playback-state of any possibly playing cinematic.
     * @return Observable stream emitting nothing and completes in case of success.
     */
    stopCinematic$(): Observable<void>;

    /**
     * Request a cinematic capture.
     * Unsubscribing from the stream cancels the encoding process.
     *
     * @param cinematicId Cinematic ID to be captured
     *
     * @return Observable emitting the download-URL when ready.
     */
    captureCinematic$(cinematicId: string): Observable<CinematicCaptureInfo>;

    /*
     * Other
     */

    /**
     * Set the value for a context-option, e.g. the turnaround-level for an environments turntable.
     */
    setContextOptionValue$(id: string, value: string): Observable<void>;
}

export const ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN = new InjectionToken<
    StrategyProvider<EngineControlStrategy>
>('Provider for EngineControlStrategy');
