import {
    first as _first,
    cloneDeep,
    flatten,
    isEqual,
    isNumber
} from 'lodash-es';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    of
} from 'rxjs';
import {
    catchError,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    first,
    map,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { animate, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SkipSelf,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatTabGroup } from '@angular/material/tabs';
import { translate } from '@jsverse/transloco';
import { BeautyshotDefinition } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { getDerivativeStaticInfo } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import { ConfigurationCompositeItemModel } from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { BrandStoreCategoryNavigationState } from '@mhp/aml-ui-shared-services';
import { CategoryItem } from '@mhp/aml-ui-shared-services/configuration/categories';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import {
    EASE_OUT_EXPO,
    TRACK_BY_ID,
    UiBaseComponent
} from '@mhp/ui-components';
import {
    ApplicationStateService,
    ErrorHandlerService,
    isOptionCollection
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../../environments/environment';
import { MirrorModeSupportService } from '../../../brand-store/mirror-mode/mirror-mode-support.service';
import {
    MirrorModeCategoryChangeHandler,
    MirrorModeCategoryInfoProvider
} from '../../../brand-store/mirror-mode/mirror-mode.interfaces';
import { SearchState } from '../../../common/search/models/search.model';
import { RegionAndLanguageSelectionInterceptor } from '../../../settings/region-selector/region.service';
import { LocalApplicationState } from '../../../state/local-application-state.interface';
import { CameraControlService } from '../../camera-control/camera-control.service';
import { buildRegionChangeInterceptor } from '../../common/region-change-interceptor';
import {
    ExtendedUiBeautyshotAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup,
    ExtendedUiOptionList
} from '../../configuration-model/configuration-interfaces';
import { InfoLayerService } from '../../info-layer/info-layer.service';
import { SearchService } from '../../search/search.service';
import {
    isBeautyshotAware,
    isExtendedUiOptionGroup,
    isExtendedUiOptionList,
    isSelectionAware
} from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../services/product-configuration-session.service';
import {
    exitSearchState,
    setActiveCategorySelection,
    setActiveSearchTerm,
    setFocusTargetNode,
    setSearchInputActive
} from '../../state/actions/configuration.actions';
import {
    selectActiveCategorySelection,
    selectActiveConfigurationSearchTerm,
    selectFocusTargetNode,
    selectSearchInputActive,
    selectShowSearchResults
} from '../../state/selectors/configuration.selectors';
import { VrInfoService } from '../../vr/vr-info.service';
import { ConfigurationCompositeItemService } from '../configuration-elements/configuration-composite-item/configuration-composite-item.service';
import { EditionsService } from '../editions/editions.service';
import { EnvironmentSelectionDialogService } from '../environment-selection/environment-selection-dialog.service';
import {
    activeSearchFilter,
    combineCategorySourceItemFilters,
    restrictNestingDepthForPersonalisationAndAccessories,
    topLevelCategoryFilter
} from '../helpers/category-filters';
import { CategoryMapperService } from '../helpers/category-mapper.service';
import { CATEGORY_DESCRIPTION_REMOVAL_MODIFIER } from '../helpers/category-modifiers';
import { DisplayedOptionsService } from '../helpers/displayed-options.service';
import { CLEAR_FOCUS_TARGET_NODE_STATE_DELAY_TIME } from './configuration-bar.constants';

interface CategoryItemSelectEvent {
    item: CategoryItem;
    source: 'internal' | 'user';
}

@UntilDestroy()
@Component({
    selector: 'mhp-configuration-bar',
    templateUrl: './configuration-bar.component.html',
    styleUrls: ['./configuration-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('toggleNestedView', [
            transition('void => *', [
                style({
                    opacity: '0'
                }),
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        opacity: 1
                    })
                )
            ]),
            transition('* => void', [
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        opacity: 0
                    })
                )
            ])
        ])
    ]
})
export class ConfigurationBarComponent
    extends UiBaseComponent
    implements
        AfterViewInit,
        OnDestroy,
        OnInit,
        MirrorModeCategoryChangeHandler,
        MirrorModeCategoryInfoProvider
{
    initialized = false;

    readonly IDENTIFIER_ENVIRONMENT_SELECTION =
        environment.appConfig.configuration.identifierEnvironmentSelection;

    readonly regionAndLanguageSelectorInterceptor: RegionAndLanguageSelectionInterceptor;

    @Input()
    revealNestedView: boolean;

    @Input()
    renderSummaryTurntableCta = true;

    @Input()
    optionsRenderingTemplate: TemplateRef<unknown>;

    @Output()
    readonly selectedTopLevelAreaChange = new EventEmitter<
        string | 'SUMMARY'
    >();

    @Output()
    readonly revealNestedViewChange = new EventEmitter<boolean>();

    @Output()
    readonly selectedCategoryPathChange: Observable<string[] | undefined>;

    @Output()
    readonly toggleMenu = new EventEmitter<Event>();

    @ViewChild('toplevelTabGroup', { static: false, read: MatTabGroup })
    topleveTabGroup: MatTabGroup;

    @ViewChild('extensionContainerLeft', {
        static: false,
        read: ElementRef<HTMLDivElement>
    })
    set extensionContainerLeft(element: ElementRef<HTMLDivElement>) {
        this.extensionContainerLeftSubject.next(element);
    }

    @ViewChild('extensionContainerCenter', {
        static: false,
        read: ElementRef<HTMLDivElement>
    })
    set extensionContainerCenter(element: ElementRef<HTMLDivElement>) {
        this.extensionContainerCenterSubject.next(element);
    }

    @ViewChild('extensionContainerRight', {
        static: false,
        read: ElementRef<HTMLDivElement>
    })
    set extensionContainerRight(element: ElementRef<HTMLDivElement>) {
        this.extensionContainerRightSubject.next(element);
    }

    @HostBinding('style.--extension-container-left-width')
    extensionContainerLeftWidth: string;

    @HostBinding('style.--extension-container-center-width')
    extensionContainerCenterWidth: string;

    @HostBinding('style.--extension-container-right-width')
    extensionContainerRightWidth: string;

    private extensionContainerLeftSubject = new ReplaySubject<
        ElementRef<HTMLDivElement>
    >(1);

    private readonly extensionContainerCenterSubject = new ReplaySubject<
        ElementRef<HTMLDivElement>
    >(1);

    private extensionContainerRightSubject = new ReplaySubject<
        ElementRef<HTMLDivElement>
    >(1);

    private readonly selectedCategoriesSubject = new BehaviorSubject<
        Set<string>
    >(new Set());

    private readonly collapsedCategoryItemsCache = new Set<string>();

    private readonly scrollPositionCache = new Map<string, number>();

    private readonly categorySelectionChangeSubject =
        new Subject<CategoryItemSelectEvent>();

    readonly updatingOptionSelection = toSignal(
        this.productConfigurationSessionService.isSelectionChangeActive$(),
        {
            initialValue: false
        }
    );

    readonly trackById = TRACK_BY_ID;

    readonly CATEGORY_EDITIONS =
        environment.appConfig.configuration.identifierRootLevelEditions;

    readonly IDENTIFIER_SUMMARY =
        environment.appConfig.configuration.identifierSummary;

    readonly CATEGORY_PERSONALISATION =
        environment.appConfig.configuration.identifierPersonalisation;

    readonly CATEGORY_ACCESSORIES =
        environment.appConfig.configuration.identifierAccessories;

    searchState: SearchState = {
        exitSearchState,
        selectActiveConfigurationSearchTerm,
        selectSearchInputActive,
        setActiveSearchTerm,
        setSearchInputActive
    };

    constructor(
        private readonly categoryMapperService: CategoryMapperService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly displayedOptionsService: DisplayedOptionsService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        @SkipSelf()
        private readonly parentChangeDetectorRef: ChangeDetectorRef,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly searchService: SearchService,
        private readonly configurationCompositeItemService: ConfigurationCompositeItemService,
        private readonly infoLayerService: InfoLayerService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly cameraControlService: CameraControlService,
        private readonly environmentSelectionDialogService: EnvironmentSelectionDialogService,
        private readonly editionsService: EditionsService,
        private readonly vrInfoService: VrInfoService,
        @Optional()
        private readonly mirrorModeSupportService?: MirrorModeSupportService
    ) {
        super();

        this.initRevealNestedViewLogic();
        this.initActiveCategoriesSync();
        this.initFocusTargetNodeLogic();
        this.initFocusOnBeautyshotOfActiveLeafCategory();
        this.initUpdateConfigurationBarHeaderLayouts();
        this.registerMirrorModeCategoryChangeHandler();
        this.registerMirrorModeCategoryInfo();
        this.selectedCategoryPathChange =
            this.buildSelectedCategoryPathChange$();

        this.regionAndLanguageSelectorInterceptor =
            buildRegionChangeInterceptor(
                this.productConfigurationSessionService
            );

        this.completeOnDestroy(
            this.selectedTopLevelAreaChange,
            this.revealNestedViewChange,
            this.categorySelectionChangeSubject,
            this.selectedCategoriesSubject,
            this.toggleMenu,
            this.extensionContainerLeftSubject,
            this.extensionContainerCenterSubject,
            this.extensionContainerRightSubject
        );
    }

    ngAfterViewInit() {
        this.initialized = true;
        // bind to the mapped items with selection information to select the correct top-level tab
        this.getCategoryHierarchyItems$()
            .pipe(
                this.takeUntilDestroy(),
                debounceTime(0), // wait a tick until changes are rendered
                map((items) => {
                    const selectedTopLevelItem = items.find(
                        (item) => item.selected
                    );
                    if (!selectedTopLevelItem) {
                        return undefined;
                    }
                    const selectedIndex = items.indexOf(selectedTopLevelItem);
                    return selectedIndex;
                }),
                distinctUntilChanged()
            )
            .subscribe((indexToSelect) => {
                if (
                    !isNumber(indexToSelect) ||
                    this.topleveTabGroup.selectedIndex === indexToSelect
                ) {
                    return;
                }
                this.topleveTabGroup.selectedIndex = indexToSelect;
                this.onSelectedTabChange(indexToSelect, 'INTERNAL');
                this.changeDetectorRef.detectChanges();
            });
    }

    ngOnInit() {
        this.initEmitInitialTopLevelCategorySelection();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        // clear selection cache on destroy for now
        this.updateSelectionCache(new Set());
    }

    onSelectedTabChange(
        tabIndex: number,
        eventSource: 'INTERNAL' | 'COMPONENT'
    ) {
        if (
            eventSource === 'COMPONENT' &&
            this.topleveTabGroup.selectedIndex !== tabIndex
        ) {
            return;
        }

        this.getCategoryHierarchyItems$()
            .pipe(take(1))
            .subscribe((toplevelEntries: (CategoryItem | undefined)[]) => {
                const toplevelEntriesCount = toplevelEntries.length;
                const normalizedIndex = tabIndex - toplevelEntriesCount;
                if (normalizedIndex < 0) {
                    const topLevelCategoryItem = toplevelEntries[tabIndex];
                    this.selectedTopLevelAreaChange.emit(
                        topLevelCategoryItem?.nameInternal
                    );
                    if (topLevelCategoryItem) {
                        this.intentSelectCategory(topLevelCategoryItem);
                    }
                } else {
                    switch (normalizedIndex) {
                        case 0:
                            this.selectedTopLevelAreaChange.emit('SUMMARY');
                            break;
                        default:
                            throw new IllegalStateError(
                                `Unexpected tab-index: ${tabIndex}`
                            );
                    }
                }
            });

        // when changing a tab, force-disable the nested-view
        if (this.revealNestedView) {
            this.revealNestedViewChange.next(false);
        }
    }

    @MemoizeObservable()
    getCategoryNavigationState$(): Observable<{
        canNavPrev: boolean;
        canNavNext: boolean;
    }> {
        return this.getCategoryHierarchyItems$().pipe(
            map(
                (items: CategoryItem[]) =>
                    this.getNavigationState(items) ?? {
                        canNavPrev: false,
                        canNavNext: false
                    }
            )
        );
    }

    @MemoizeObservable()
    getCategoryHierarchyItems$(): Observable<CategoryItem[]> {
        return this.getCategoryItemsFiltered$().pipe(
            map((categoryItems) => {
                const clonedCategoryItems = cloneDeep(categoryItems);
                const flattenedCategoryItems =
                    this.getFlattenedCategoryItems(clonedCategoryItems);

                // restore previous selection
                const selectionCache = this.getSelectionCache();
                const collapsedCache = this.collapsedCategoryItemsCache;
                flattenedCategoryItems.forEach((item) => {
                    if (!item.isCategorySelector) {
                        return;
                    }
                    item.selected = selectionCache.has(item.id);
                    item.collapsed = collapsedCache.has(item.id);
                });

                return [flattenedCategoryItems, clonedCategoryItems];
            }),
            switchMap(([flattenedCategoryItems, clonedCategoryItems]) => {
                let changedCategoryInput = true;

                return this.categorySelectionChangeSubject.pipe(
                    startWith(undefined),
                    map((selectedCategoryChangeEvent) => {
                        if (!changedCategoryInput) {
                            if (selectedCategoryChangeEvent) {
                                this.processSelection(
                                    clonedCategoryItems,
                                    selectedCategoryChangeEvent
                                );
                            }
                        }

                        changedCategoryInput = false;

                        this.ensureOneSelected(clonedCategoryItems);

                        const selectionCache = this.getSelectionCache();
                        const collapsedCache = this.collapsedCategoryItemsCache;

                        selectionCache.clear();
                        flattenedCategoryItems
                            .filter((item) => item.selected)
                            .forEach((item) => selectionCache.add(item.id));

                        collapsedCache.clear();
                        flattenedCategoryItems
                            .filter((item) => item.collapsed)
                            .forEach((item) => collapsedCache.add(item.id));

                        this.updateSelectionCache(selectionCache);

                        // always emit a fresh copy of the altered category items
                        return cloneDeep(clonedCategoryItems);
                    })
                );
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getActiveOptions$(): Observable<ExtendedUiOptionCode[] | undefined> {
        return this.displayedOptionsService.getOptionsContainedInBranch$(
            this.getCurrentlyActiveLeafCategory$().pipe(
                map((itemInfo) => {
                    if (itemInfo?.nestingDepth === 0) {
                        // when there is a toplevel category active only, we return no items
                        return undefined;
                    }
                    return itemInfo?.item.id;
                })
            ),
            this.getOptionGroupsFiltered$(),
            {
                skipAllOnEmptyBranchId: true
            }
        );
    }

    @MemoizeObservable()
    getActiveProductId$() {
        return this.productConfigurationSessionService
            .getActiveConfiguration$()
            .pipe(map((activeConfiguration) => activeConfiguration?.productId));
    }

    /**
     * For the currently active leaf-category, check if its parent is a collection node and if true,
     * determine the currently active option withing all its sub-options.
     */
    @MemoizeObservable()
    getSingleSelectedOptionForActiveCollectionCategory$(): Observable<
        ExtendedUiOptionCode | undefined
    > {
        const activeParentCollection$: Observable<
            ExtendedUiOptionCollection | undefined
        > = combineLatest([
            this.getCurrentlyActiveLeafCategory$(),
            this.productConfigurationSessionService.getOptionGroups$()
        ]).pipe(
            map(([activeLeafCategory, optionGroups]) => {
                if (!activeLeafCategory || !optionGroups) {
                    return undefined;
                }
                const foundParent = this.nodeLookupService.findNodeById(
                    activeLeafCategory.item.id,
                    optionGroups
                )?.parent;
                if (!foundParent || !isOptionCollection(foundParent)) {
                    return undefined;
                }
                return foundParent;
            })
        );

        return this.displayedOptionsService
            .getOptionsContainedInBranch$(
                of(undefined),
                activeParentCollection$.pipe(
                    map((collection) => (collection ? [collection] : undefined))
                )
            )
            .pipe(
                map((options) => options.find((option) => option.selected)),
                lazyShareReplay()
            );
    }

    @MemoizeObservable()
    getActiveBeautyshotDefinition$(): Observable<
        BeautyshotDefinition | undefined
    > {
        return combineLatest([
            this.getOptionGroupsFiltered$(),
            this.getCurrentlyActiveLeafCategory$().pipe(
                map((itemInfo) => itemInfo?.item.id)
            )
        ]).pipe(
            debounceTime(0),
            map(([optionGroups, activeLeafCategory]) => {
                if (!optionGroups || !activeLeafCategory) {
                    return undefined;
                }
                const nodePath = this.nodeLookupService.getNodePathToNode(
                    (node) => node.id === activeLeafCategory,
                    optionGroups
                );
                if (!nodePath) {
                    return undefined;
                }

                return nodePath
                    .reverse()
                    .find(
                        (
                            node
                        ): node is ExtendedUiBeautyshotAwareConfigurationMetaItem =>
                            isBeautyshotAware(node)
                    )?.beautyshot;
            })
        );
    }

    /**
     * See {@link MirrorModeCategoryInfoProvider}.
     */
    @MemoizeObservable()
    getActiveCategoryInfo$(): Observable<{
        categoryNavigationState?: BrandStoreCategoryNavigationState;
    }> {
        return combineLatest([
            this.getCategoryHierarchyItems$(),
            this.getCurrentlyActiveLeafCategory$()
        ]).pipe(
            map(([categoryItems, activeLeafItemInfo]) => {
                const filteredCategoryItems = categoryItems.filter(
                    (item) =>
                        ![
                            this.CATEGORY_PERSONALISATION,
                            this.CATEGORY_ACCESSORIES,
                            this.IDENTIFIER_SUMMARY
                        ].includes(item.nameInternal)
                );

                const flattenedLeafItems = this.getFlattenedCategoryItems(
                    filteredCategoryItems
                ).filter(
                    (item) =>
                        (!item.children ||
                            item.children.every(
                                (child) => !child.isCategorySelector
                            )) &&
                        item.isCategorySelector
                );
                const localActiveLeafItem = flattenedLeafItems.find(
                    (item) => item.id === activeLeafItemInfo?.item.id
                );

                if (!localActiveLeafItem) {
                    return {
                        categoryNavigationState: undefined
                    };
                }

                const selectedItemIndex =
                    flattenedLeafItems.indexOf(localActiveLeafItem);

                return {
                    categoryNavigationState: {
                        totalSteps: flattenedLeafItems.length,
                        currentStep: selectedItemIndex,
                        categoryItems: filteredCategoryItems
                    } satisfies BrandStoreCategoryNavigationState
                };
            })
        );
    }

    intentSelectCategory(
        categoryItem: CategoryItem,
        source: 'internal' | 'user' = 'internal'
    ) {
        // distinguish between category selector and other items
        if (categoryItem.isCategorySelector) {
            this.categorySelectionChangeSubject.next({
                item: categoryItem,
                source
            });
        } else {
            this.getOptionGroupsFiltered$()
                .pipe(take(1))
                .subscribe((optionGroups) => {
                    if (!optionGroups) {
                        return;
                    }
                    const matchingNode = this.nodeLookupService.findNodeById(
                        categoryItem.id,
                        optionGroups
                    )?.node;
                    if (!matchingNode || !isSelectionAware(matchingNode)) {
                        return;
                    }
                    this.intentSelectOption(matchingNode.id);
                });
        }
    }

    intentStepCategory(
        direction: 0 | 1,
        categoryChangeCallback?: (categoryItem: CategoryItem) => boolean
    ) {
        // traverse down the selected category-path
        combineLatest([
            this.getCategoryHierarchyItems$(),
            this.getCurrentlyActiveLeafCategory$()
        ])
            .pipe(first())
            .subscribe(([items, activeLeafItemInfo]) => {
                if (!items || !activeLeafItemInfo) {
                    return;
                }
                // get leaf items
                const flattenedLeafItems = this.getFlattenedCategoryItems(
                    items
                ).filter(
                    (item) =>
                        (!item.children ||
                            item.children.every(
                                (child) => !child.isCategorySelector
                            )) &&
                        item.isCategorySelector
                );
                const localActiveLeafItem = flattenedLeafItems.find(
                    (item) => item.id === activeLeafItemInfo.item.id
                );

                if (!localActiveLeafItem) {
                    return;
                }

                const selectedItemIndex =
                    flattenedLeafItems.indexOf(localActiveLeafItem);
                if (selectedItemIndex < 0) {
                    return;
                }
                let targetCategoryItem: CategoryItem | undefined;
                if (
                    direction === 1 &&
                    selectedItemIndex < flattenedLeafItems.length - 1
                ) {
                    targetCategoryItem =
                        flattenedLeafItems[selectedItemIndex + 1];
                } else if (direction === 0 && selectedItemIndex > 0) {
                    targetCategoryItem =
                        flattenedLeafItems[selectedItemIndex - 1];
                }
                if (
                    targetCategoryItem &&
                    (!categoryChangeCallback ||
                        categoryChangeCallback(targetCategoryItem))
                ) {
                    this.intentSelectCategory(targetCategoryItem);
                }
            });
    }

    /**
     * Implementation for mirror-mode category-change handler
     * @param direction
     * @param callback
     */
    intentChangeCategory(
        direction: 0 | 1,
        callback: (categoryItem: CategoryItem) => boolean
    ) {
        this.intentStepCategory(direction, callback);
    }

    /**
     * Explicitly use the arrow-function syntax to bind the context to the ConfigurationBarComponent instance
     * as we're passing a reference to this function optionally to the #optionsRenderingTemplate.
     * @param id The id of the option that has been selected.
     */
    intentSelectOption = (id: string) => {
        this.productConfigurationSessionService
            .getOptionGroups$()
            .pipe(
                take(1),
                switchMap((optionGroups) => {
                    if (!optionGroups) {
                        return EMPTY;
                    }
                    const editionNode =
                        this.editionsService.getEditionNodeOtherThanNoPack(
                            id,
                            optionGroups
                        );
                    if (editionNode) {
                        return this.editionsService.intentShowEditionsInfo$(
                            editionNode
                        );
                    }

                    return this.productConfigurationSessionService.updateNodeSelection(
                        id
                    );
                })
            )
            .subscribe();
    };

    /**
     * Explicitly use the arrow-function syntax to bind the context to the ConfigurationBarComponent instance
     * as we're passing a reference to this function optionally to the #optionsRenderingTemplate.
     * @param option The option for which the info should be shown.
     */
    intentRequestOptionInfo = (option: ExtendedUiOptionCode) => {
        this.infoLayerService
            .showInfoLayerForOptionCode$(option)
            .pipe(this.takeUntilDestroy())
            .subscribe();
    };

    intentExitSearch() {
        this.applicationStateService.dispatch(exitSearchState());
    }

    intentToggleMenu(event: Event) {
        this.toggleMenu.emit(event);
    }

    intentShowEnvironmentLayer() {
        this.environmentSelectionDialogService
            .showEnvironmentSelectionDialog$(
                this.viewContainerRef,
                this.componentFactoryResolver
            )
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }

    /**
     * Show additional info for the category-item in question.
     * @param categoryItem
     */
    intentShowInfoForCategoryItem(categoryItem: CategoryItem) {
        if (!categoryItem.description) {
            return;
        }

        this.infoLayerService
            .showInfoLayer$({
                description: categoryItem.description,
                itemType: 'text',
                label: categoryItem.label,
                thumbnailUrls: []
            })
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    intentGoToSummary() {
        this.applicationStateService.dispatch(
            setFocusTargetNode({
                id: this.IDENTIFIER_SUMMARY
            })
        );
    }

    /**
     * Show the editions-info-dialog to the user.
     */
    intentShowEditionsInfo() {
        this.editionsService
            .intentShowEditionsInfo$(undefined, {
                forceShowDialog: true
            })
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    onClickTabGroupContainer() {
        // emit intent to stop revealing nested view
        this.revealNestedViewChange.next(false);
    }

    onScrollPositionChange(toplevelEntryId: string, scrollPosition: number) {
        this.scrollPositionCache.set(toplevelEntryId, scrollPosition);
    }

    getScrollPosition(toplevelEntryId: string) {
        return this.scrollPositionCache.get(toplevelEntryId) || 0;
    }

    @MemoizeObservable()
    nextCategoryAvailable$(): Observable<boolean> {
        return of(true);
    }

    @MemoizeObservable()
    prevCategoryAvailable$(): Observable<boolean> {
        return of(true);
    }

    @MemoizeObservable()
    isSearchInputActive$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectSearchInputActive);
    }

    @MemoizeObservable()
    isInteriorEnvironmentSelectionEnabled$(): Observable<boolean> {
        return this.getActiveProductId$().pipe(
            map((productId) => {
                if (!productId) {
                    return false;
                }
                return !getDerivativeStaticInfo(productId)
                    .disableInteriorEnvironmentSelection;
            })
        );
    }

    @MemoizeObservable()
    isSummaryActive$(): Observable<boolean> {
        return this.getCategoryHierarchyItems$().pipe(
            map(
                (items) =>
                    !!items.find(
                        (item) =>
                            item.selected &&
                            item.nameInternal === this.IDENTIFIER_SUMMARY
                    )
            )
        );
    }

    /**
     * Returns information about the selection state of an edition-option (sub-option of
     * the EDITIONS (Group) > EDITIONS (Collection) hierarchy.
     */
    @MemoizeObservable()
    isEditionActive$(): Observable<boolean> {
        return this.editionsService.isEditionActive$();
    }

    @MemoizeObservable()
    getActiveSearchTerm$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveConfigurationSearchTerm);
    }

    @MemoizeObservable()
    isDisplayingSearchResults$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectShowSearchResults);
    }

    @MemoizeObservable()
    getSearchResultCountForRootGroup$(id: string): Observable<number> {
        return this.displayedOptionsService
            .getOptionsContainedInBranch$(
                of(id),
                this.getOptionGroupsFiltered$()
            )
            .pipe(
                map((options) => options.length),
                lazyShareReplay()
            );
    }

    @MemoizeObservable()
    getConfigurationCompositeItemModels$(): Observable<
        ConfigurationCompositeItemModel[] | undefined
    > {
        const activeContentAwareNode$ = combineLatest([
            this.getCurrentlyActiveLeafCategory$().pipe(
                map((itemInfo) => itemInfo?.item.id)
            ),
            this.getOptionGroupsFiltered$()
        ]).pipe(
            debounceTime(0),
            map(([leafCategoryItemId, optionGroupsFiltered]) => {
                if (!leafCategoryItemId || !optionGroupsFiltered) {
                    return undefined;
                }
                const matchingNode = this.nodeLookupService.findNodeById(
                    leafCategoryItemId,
                    optionGroupsFiltered
                )?.node;

                if (!matchingNode || !isExtendedUiOptionGroup(matchingNode)) {
                    return undefined;
                }
                return matchingNode;
            })
        );

        return this.configurationCompositeItemService.mapToConfigurationCompositeItemModels$(
            activeContentAwareNode$
        );
    }

    @MemoizeObservable()
    private getCurrentlyActiveLeafCategory$(): Observable<
        { item: CategoryItem; nestingDepth: number } | undefined
    > {
        return this.getCategoryHierarchyItems$().pipe(
            map((items) => this.getCurrentSelectedCategory(items)),
            distinctUntilChanged(isEqual),
            lazyShareReplay()
        );
    }

    private getSelectionCache(): Set<string> {
        return new Set(this.selectedCategoriesSubject.value);
    }

    private updateSelectionCache(selectionCache: Set<string>) {
        this.applicationStateService.dispatch(
            setActiveCategorySelection({
                selection: Array.from(selectionCache)
            })
        );
    }

    private initActiveCategoriesSync() {
        this.applicationStateService
            .getLocalState()
            .pipe(
                selectActiveCategorySelection,
                this.takeUntilDestroy(),
                map((selection) => new Set(selection))
            )
            .subscribe(this.selectedCategoriesSubject);
    }

    private processSelection(
        items: CategoryItem[],
        changeEvent: CategoryItemSelectEvent
    ): boolean {
        const selectedItem = items.find((item) =>
            this.processSelectRecurse(item, changeEvent)
        );
        if (selectedItem) {
            // deselect others
            items
                .filter((item) => item !== selectedItem)
                .forEach((item) => {
                    item.selected = false;
                });
        }
        return !!selectedItem;
    }

    private processSelectRecurse(
        categoryItem: CategoryItem,
        changeEvent: CategoryItemSelectEvent
    ): boolean {
        if (categoryItem.children && categoryItem.isCategorySelector) {
            const childSelected = this.processSelection(
                categoryItem.children,
                changeEvent
            );

            if (childSelected) {
                categoryItem.selected = true;
                categoryItem.collapsed = false;
                return true;
            }
        }

        if (
            categoryItem.id === changeEvent.item.id &&
            categoryItem.isCategorySelector
        ) {
            const currentSelectionState = categoryItem.selected;
            categoryItem.selected = true;

            if (!currentSelectionState) {
                categoryItem.collapsed = false;
            } else if (changeEvent.source === 'user') {
                categoryItem.collapsed = !categoryItem.collapsed;
            }

            if (categoryItem.children) {
                this.ensureOneSelected(categoryItem.children);
            }

            return true;
        }

        return false;
    }

    private ensureOneSelected(items: CategoryItem[]) {
        let selectedOrFirstItem = items.find(
            (item) => item.isCategorySelector && item.selected
        );

        if (!selectedOrFirstItem) {
            selectedOrFirstItem = items.find((item) => item.isCategorySelector);
        }

        if (!selectedOrFirstItem) {
            return;
        }

        if (selectedOrFirstItem.children) {
            this.ensureOneSelected(selectedOrFirstItem.children);
        }

        selectedOrFirstItem.selected = true;
    }

    private getCurrentSelectedCategory(
        items: CategoryItem[],
        nestingDepth = 0
    ): { item: CategoryItem; nestingDepth: number } | undefined {
        for (const item of items) {
            const foundItemInfo = this.getCurrentSelectedCategoryRecurse(
                item,
                nestingDepth
            );
            if (foundItemInfo) {
                return foundItemInfo;
            }
        }
        return undefined;
    }

    private getCurrentSelectedCategoryRecurse(
        item: CategoryItem,
        nestingDepth: number
    ): { item: CategoryItem; nestingDepth: number } | undefined {
        if (!item.selected || !item.isCategorySelector) {
            return undefined;
        }
        if (item.children?.find((child) => child.isCategorySelector)) {
            return this.getCurrentSelectedCategory(
                item.children,
                nestingDepth + 1
            );
        }
        return { item, nestingDepth };
    }

    private getFlattenedCategoryItems(
        items: CategoryItem[],
        itemFiler: (item: CategoryItem) => boolean = () => true
    ): CategoryItem[] {
        const reducedItems = items.reduce(
            (collectedItems, item) => {
                if (!itemFiler(item)) {
                    return collectedItems;
                }
                collectedItems.push(item);
                if (item.children) {
                    collectedItems.push(
                        ...this.getFlattenedCategoryItems(
                            item.children,
                            itemFiler
                        )
                    );
                }
                return collectedItems;
            },
            <CategoryItem[]>[]
        );
        return flatten(reducedItems);
    }

    @MemoizeObservable()
    private getCategoryItemsFiltered$(): Observable<CategoryItem[]> {
        return this.searchService.getActiveSearchTerm$().pipe(
            switchMap((activeSearchTerm) =>
                this.categoryMapperService.getCategoryItems$(
                    this.getOptionGroupsFiltered$(),
                    combineCategorySourceItemFilters(
                        activeSearchFilter(!!activeSearchTerm),
                        topLevelCategoryFilter,
                        restrictNestingDepthForPersonalisationAndAccessories
                    ),
                    CATEGORY_DESCRIPTION_REMOVAL_MODIFIER
                )
            ),
            withLatestFrom(this.getOptionGroupsFiltered$()),
            map(([categoryItems, optionGroups]) => {
                if (!optionGroups) {
                    return categoryItems;
                }
                // well.... We have a special requirement for the stitching-part: https://jira.mymhp.net/browse/IE-2184
                const interiorOptionsItem = categoryItems.find(
                    (categoryItem) =>
                        categoryItem.nameInternal ===
                        environment.appConfig.configuration
                            .identifierInteriorOptions
                );
                if (!interiorOptionsItem) {
                    return categoryItems;
                }

                const interiorDropdownCategory =
                    interiorOptionsItem.children?.find(
                        (categoryItem) =>
                            environment.appConfig.configuration.identifierStitchingTypeSelection.indexOf(
                                categoryItem.nameInternal
                            ) > -1
                    );

                const interiorStitchingListNode =
                    this.nodeLookupService.findNode(
                        (node): node is ExtendedUiOptionList =>
                            isExtendedUiOptionList(node) &&
                            environment.appConfig.configuration.identifierStitchingListCodes.indexOf(
                                node.code
                            ) > -1,
                        optionGroups
                    )?.node;

                if (!interiorStitchingListNode) {
                    return categoryItems;
                }

                const interiorListCategory = interiorOptionsItem.children?.find(
                    (categoryItem) =>
                        categoryItem.id ===
                        interiorStitchingListNode?.content[0].id
                );

                if (!interiorDropdownCategory || !interiorListCategory) {
                    return categoryItems;
                }

                interiorOptionsItem.children =
                    interiorOptionsItem.children?.filter(
                        (categoryItem) =>
                            categoryItem !== interiorDropdownCategory
                    );

                interiorListCategory.children = [interiorDropdownCategory];

                return categoryItems;
            }),
            distinctUntilChanged(isEqual),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    private getOptionGroupsFiltered$(): Observable<
        ExtendedUiOptionGroup[] | undefined
    > {
        return this.productConfigurationSessionService.getOptionGroups$().pipe(
            switchMap(
                (
                    optionGroups
                ): Observable<ExtendedUiOptionGroup[] | undefined> => {
                    if (!optionGroups) {
                        return of(undefined);
                    }
                    return combineLatest(
                        optionGroups.map((rootOptionGroup) =>
                            this.searchService.getFilteredOptions$(
                                of([rootOptionGroup])
                            )
                        )
                    ).pipe(
                        map((groupFilterResults) =>
                            optionGroups.map(
                                (
                                    rootOptionGroup,
                                    index
                                ): ExtendedUiOptionGroup => {
                                    const filteredOptionGroup = _first(
                                        groupFilterResults[index]
                                    );
                                    return {
                                        ...rootOptionGroup,
                                        content:
                                            filteredOptionGroup?.content || []
                                    };
                                }
                            )
                        )
                    );
                }
            ),
            lazyShareReplay()
        );
    }

    /**
     * Get the navigation state (can navigate prev / next) for the given category items.
     *
     * @param items The items to get the navigation state for.
     * @param level The current nesting level.
     * @returns The navigation state for the given category items.
     */
    private getNavigationState(
        items: CategoryItem[],
        level = 0
    ):
        | {
              canNavPrev: boolean;
              canNavNext: boolean;
          }
        | undefined {
        // we can't navigate to a category-selector, so exclude those from the logic
        const itemsWithoutCategorySelectors = items.filter(
            (child) => child.isCategorySelector
        );
        const selectedItem = itemsWithoutCategorySelectors.find(
            (item) => item.selected
        );
        // if there is no selected item here, there won't be any down the hierarchy
        if (!selectedItem) {
            return undefined;
        }

        let childrenNavigationState:
            | {
                  canNavPrev: boolean;
                  canNavNext: boolean;
              }
            | undefined = {
            canNavPrev: false,
            canNavNext: false
        };
        if (selectedItem.children) {
            // take a look at the nav-state down the hierarchy
            childrenNavigationState = this.getNavigationState(
                selectedItem.children,
                level + 1
            );

            if (
                childrenNavigationState?.canNavPrev &&
                childrenNavigationState?.canNavNext
            ) {
                // we can navigate both prev and next further down, so stop here
                return childrenNavigationState;
            }
        }

        if (level > 0) {
            // only for levels > root level, prev and next navigation should be possible
            const selectedItemIndex =
                itemsWithoutCategorySelectors.indexOf(selectedItem);
            if (
                selectedItemIndex > 0 &&
                selectedItemIndex < itemsWithoutCategorySelectors.length - 1
            ) {
                // somewhere in the middle, so we can navigate both prev and next
                return {
                    canNavPrev: true,
                    canNavNext: true
                };
            }
            if (
                selectedItemIndex === 0 &&
                itemsWithoutCategorySelectors.length > 1
            ) {
                // the selected item is the first and we have more than this item on this level -> next possible
                return {
                    // on this level, we cannot navigate prev, but maybe it's possible further down the hierarchy
                    canNavPrev: !!childrenNavigationState?.canNavPrev,
                    canNavNext: true
                };
            }
            if (
                selectedItemIndex ===
                    itemsWithoutCategorySelectors.length - 1 &&
                itemsWithoutCategorySelectors.length > 1
            ) {
                // the selected item is the last and we have more than this item on this level -> prev possible
                return {
                    canNavPrev: true,
                    // on this level, we cannot navigate next, but maybe it's possible further down the hierarchy
                    canNavNext: !!childrenNavigationState?.canNavNext
                };
            }
        }
        return childrenNavigationState;
    }

    private initRevealNestedViewLogic() {
        this.observeProperty<ConfigurationBarComponent, boolean>(
            'revealNestedView'
        )
            .pipe(distinctUntilChanged())
            .subscribe((doReveal) => {
                this.revealNestedViewChange.next(doReveal);
            });
    }

    private initFocusTargetNodeLogic() {
        this.applicationStateService
            .getLocalState()
            .pipe(
                selectFocusTargetNode,
                filter(
                    (
                        targetNodeDetails
                    ): targetNodeDetails is {
                        id: string;
                        skipCameraChange?: boolean;
                    } => !!targetNodeDetails
                ),
                withLatestFrom(this.getCategoryItemsFiltered$()),
                tap(([targetNodeDetails, categoryItems]) => {
                    const flattenedItems =
                        this.getFlattenedCategoryItems(categoryItems);
                    const targetNode = flattenedItems.find(
                        (item) => item.id === targetNodeDetails.id
                    );
                    if (!targetNode) {
                        return;
                    }
                    this.intentSelectCategory(targetNode);
                }),
                delay(CLEAR_FOCUS_TARGET_NODE_STATE_DELAY_TIME),
                tap(() => {
                    // clear the target node
                    this.applicationStateService.dispatch(
                        setFocusTargetNode({
                            id: undefined
                        })
                    );
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    private initFocusOnBeautyshotOfActiveLeafCategory() {
        this.getCurrentlyActiveLeafCategory$()
            .pipe(map((itemInfo) => itemInfo?.item.id))
            .pipe(
                debounceTime(0),
                withLatestFrom(
                    this.getOptionGroupsFiltered$(),
                    this.vrInfoService.isVrActive$(),
                    this.applicationStateService
                        .getLocalState()
                        .pipe(selectFocusTargetNode)
                ),
                map(
                    ([
                        activeLeafCategory,
                        optionGroups,
                        isVrActive,
                        activeFocusTargetNode
                    ]) => {
                        if (
                            !optionGroups ||
                            !activeLeafCategory ||
                            activeFocusTargetNode?.skipCameraChange
                        ) {
                            // in case no options are available, there is no active leaf category. If a focus-target-node action is active where no cameras should be changed, adhere to this setting
                            return undefined;
                        }
                        return (
                            this.nodeLookupService
                                .getNodePathToNode(
                                    (node) => node.id === activeLeafCategory,
                                    optionGroups
                                )
                                ?.map((node): string | undefined => {
                                    const beautyshotDefinition =
                                        isBeautyshotAware(node)
                                            ? node.beautyshot
                                            : undefined;
                                    return isVrActive
                                        ? beautyshotDefinition?.cameraIdVr
                                        : beautyshotDefinition?.cameraId;
                                })
                                // get the first defined camera (might be either regular or VR, depending on current context)
                                ?.find((cameraId) => !!cameraId)
                        );
                    }
                ),
                filter((cameraId): cameraId is string => !!cameraId),
                distinctUntilChanged(),
                switchMap((cameraId) =>
                    this.cameraControlService
                        .setActiveCamera$(cameraId, {
                            skipIfUnchanged: true,
                            revertToDefaultCameraIfNonExisting: true
                        })
                        .pipe(
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: () =>
                                    translate(
                                        'CONFIGURATOR.FEATURES.CAMERAS.ERRORS.SET_CAMERA'
                                    )
                            }),
                            catchError(() => EMPTY)
                        )
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    /**
     * Initially emit the active top level area.
     * @private
     */
    private initEmitInitialTopLevelCategorySelection() {
        this.getCategoryHierarchyItems$()
            .pipe(take(1), this.takeUntilDestroy())
            .subscribe((toplevelEntries: (CategoryItem | undefined)[]) => {
                if (!toplevelEntries || !toplevelEntries[0]?.nameInternal) {
                    return;
                }
                this.selectedTopLevelAreaChange.emit(
                    toplevelEntries[0]?.nameInternal
                );
            });
    }

    /**
     * This initialized logic to update the left-, center- and right-extension-container widths
     * which are required to calculate positioning of the root-level category-tab-group header.
     * @private
     */
    private initUpdateConfigurationBarHeaderLayouts() {
        // no need to take until destroy, as the subjects are completed on destruction
        combineLatest([
            this.extensionContainerLeftSubject,
            this.extensionContainerCenterSubject,
            this.extensionContainerRightSubject
        ])
            .pipe(
                debounceTime(0),
                switchMap((elementRefs) =>
                    combineLatest(
                        elementRefs.map(
                            ({ nativeElement }) =>
                                new Observable<number>((subscriber) => {
                                    const resizeObserver = new ResizeObserver(
                                        () => {
                                            subscriber.next(
                                                nativeElement.getBoundingClientRect()
                                                    .width
                                            );
                                        }
                                    );
                                    resizeObserver.observe(nativeElement);

                                    return () => {
                                        resizeObserver.disconnect();
                                    };
                                })
                        )
                    )
                )
            )
            .subscribe(
                ([leftElementWidth, centerElementWidth, rightElementWidth]) => {
                    this.extensionContainerLeftWidth = `${leftElementWidth}px`;
                    this.extensionContainerCenterWidth = `${centerElementWidth}px`;
                    this.extensionContainerRightWidth = `${rightElementWidth}px`;
                    this.parentChangeDetectorRef.detectChanges();
                }
            );
    }

    private buildSelectedCategoryPathChange$() {
        return combineLatest([
            this.getCurrentlyActiveLeafCategory$(),
            this.getOptionGroupsFiltered$()
        ]).pipe(
            map(([activeLeafCategory, optionGroupsFiltered]) => {
                if (!activeLeafCategory) {
                    return undefined;
                }
                const activeLeafCategoryId = activeLeafCategory.item.id;

                return this.nodeLookupService
                    .getNodePathToNode(
                        (node) => node.id === activeLeafCategoryId,
                        optionGroupsFiltered
                    )
                    ?.reverse()
                    .map((node) => node.id);
            })
        );
    }

    /**
     * Register a category-change-handler to support the mirror-mode to be able to change categories.
     * @private
     */
    private registerMirrorModeCategoryChangeHandler() {
        this.destroy$.subscribe(
            this.mirrorModeSupportService?.registerCategoryChangeHandler(this)
        );
    }

    /**
     * Register ourselves as category-info-provider to support the mirror-mode in displaying category-infos.
     * @private
     */
    private registerMirrorModeCategoryInfo() {
        this.destroy$.subscribe(
            this.mirrorModeSupportService?.registerCategoryInfoProvider(this)
        );
    }
}
