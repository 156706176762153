import { first, identity, isNumber, isString, last } from 'lodash-es';
import { BehaviorSubject, Observable, of, switchMap, throwError } from 'rxjs';
import {
    catchError,
    filter,
    map,
    switchMapTo,
    take,
    tap,
    timeout,
    withLatestFrom
} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
    ScreenshotOptions,
    ScreenshotRequestPayload,
    ScreenshotResponsePayload
} from '@mhp-immersive-exp/contracts/src';
import { PlayAnimationRequestPayload } from '@mhp-immersive-exp/contracts/src/animation/play-animation-request.interface';
import { PlayAnimationResponsePayload } from '@mhp-immersive-exp/contracts/src/animation/play-animation-response.interface';
import { SetCameraRequestPayload } from '@mhp-immersive-exp/contracts/src/camera/set-camera-request.interface';
import { SetCameraResponsePayload } from '@mhp-immersive-exp/contracts/src/camera/set-camera-response.interface';
import {
    CaptureCinematicRequestPayload,
    REQUEST_CAPTURE_CINEMATIC
} from '@mhp-immersive-exp/contracts/src/capture-cinematic/capture-cinematic-request.interface';
import { CaptureCinematicResponsePayload } from '@mhp-immersive-exp/contracts/src/capture-cinematic/capture-cinematic-response.interface';
import {
    CapturedCinematicEventPayload,
    EVENT_CAPTURED_CINEMATIC
} from '@mhp-immersive-exp/contracts/src/capture-cinematic/captured-cinematic-event.interface';
import {
    REQUEST_STOP_CAPTURE_CINEMATIC,
    StopCaptureCinematicRequestPayload
} from '@mhp-immersive-exp/contracts/src/capture-cinematic/stop-capture-cinematic-request.interface';
import {
    PlayCinematicRequestPayload,
    REQUEST_PLAY_CINEMATIC
} from '@mhp-immersive-exp/contracts/src/cinematic/play-cinematic-request.interface';
import { PlayCinematicResponsePayload } from '@mhp-immersive-exp/contracts/src/cinematic/play-cinematic-response.interface';
import { REQUEST_STOP_CINEMATIC } from '@mhp-immersive-exp/contracts/src/cinematic/stop-cinematic-request.interface';
import {
    REQUEST_SET_CONTEXT_OPTION,
    SetContextOptionPayload
} from '@mhp-immersive-exp/contracts/src/context-option/set-context-option-request.interface';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import {
    REQUEST_SET_DAYTIME,
    SetDaytimeRequestPayload
} from '@mhp-immersive-exp/contracts/src/environment/set-daytime-request.interface';
import { SetDaytimeResponsePayload } from '@mhp-immersive-exp/contracts/src/environment/set-daytime-response.interface';
import {
    REQUEST_SET_ENVIRONMENT,
    SetEnvironmentRequest
} from '@mhp-immersive-exp/contracts/src/environment/switch-environments-request.interface';
import { SetEnvironmentResponse } from '@mhp-immersive-exp/contracts/src/environment/switch-environments-response.interface';
import {
    PlayHighlightRequestPayload,
    REQUEST_PLAY_HIGHLIGHT
} from '@mhp-immersive-exp/contracts/src/highlight/play-highlight-request.interface';
import { PlayHighlightResponsePayload } from '@mhp-immersive-exp/contracts/src/highlight/play-highlight-response.interface';
import { REQUEST_STOP_HIGHLIGHT } from '@mhp-immersive-exp/contracts/src/highlight/stop-highlight-request.interface';
import { Request } from '@mhp-immersive-exp/contracts/src/request/request.enum';
import { ResolveRequest } from '@mhp-immersive-exp/contracts/src/resolve/resolve-request.interface';
import { ResolveResponse } from '@mhp-immersive-exp/contracts/src/resolve/resolve-response.interface';
import {
    REQUEST_SET_WATERMARK,
    SetWatermarkRequestPayload
} from '@mhp-immersive-exp/contracts/src/watermark/set-watermark-request.interface';
import { IllegalStateError, lazyShareReplay } from '@mhp/common';
import { ApplyApplicationStatePayload } from '@mhp/communication-models';

import { SocketIOService } from '../../communication';
import { FeatureAvailabilityService } from '../../feature-availability';
import {
    CinematicCaptureInfo,
    EngineControlState,
    EngineControlStrategy,
    ProductConfigurationInfo,
    ScreenshotSource
} from '../engine-control-strategy.interface';

export interface EngineControlSocketStrategyConfig {
    // base-url to the http-server containing protocol, host and port, e.g. http://127.0.0.1:4444/`
    engineHttpServerUrl: string | Observable<string>;
    // timeout when waiting for a cinematic-download
    cinematicDownloadTimeout?: number;
    // the optional origin (e.g. https://www.example.com) to be used when constructing a cinematic-download URL. If no host is provided the download-URL provided by the engine is not modified
    cinematicDownloadOrigin?: string;
}

export const ENGINE_CONTROL_SOCKET_STRATEGY_CONFIG_TOKEN =
    new InjectionToken<EngineControlSocketStrategyConfig>(
        'EngineControlSocketStrategyConfig'
    );

const CINEMATIC_DOWNLOAD_TIMEOUT_DEFAULT = 3 * 60 * 1000;

/**
 * ProductConfigurationCommunicationFacade implementation based on socket-io calls.
 * To be used when application is used in context where engine is available.
 */
@Injectable()
export class EngineControlSocketStrategy implements EngineControlStrategy {
    private readonly engineHttpUrlSubject = new BehaviorSubject<
        string | undefined
    >(undefined);

    constructor(
        protected readonly socketIoService: SocketIOService,
        private readonly httpClient: HttpClient,
        private readonly featureAvailabilityService: FeatureAvailabilityService,
        @Inject(ENGINE_CONTROL_SOCKET_STRATEGY_CONFIG_TOKEN)
        private readonly config: EngineControlSocketStrategyConfig
    ) {
        let engineHttpUrl$: Observable<string | undefined>;

        if (isString(config.engineHttpServerUrl)) {
            engineHttpUrl$ = of(config.engineHttpServerUrl);
        } else {
            engineHttpUrl$ = config.engineHttpServerUrl;
        }

        engineHttpUrl$.subscribe((engineHttpUrl) => {
            this.engineHttpUrlSubject.next(
                engineHttpUrl
                    ? this.ensureEndsWithSlash(engineHttpUrl)
                    : undefined
            );
        });

        if (config.cinematicDownloadOrigin) {
            // try to parse as URL
            try {
                // eslint-disable-next-line no-new
                new URL(config.cinematicDownloadOrigin);
            } catch (error) {
                throw new IllegalStateError(
                    `cinematicDownloadOrigin should be parsable as URL but isn't: ${config.cinematicDownloadOrigin}`,
                    error
                );
            }
        }
    }

    applyEngineControlState$(
        engineControlState: EngineControlState
    ): Observable<void> {
        return this.socketIoService.request<ApplyApplicationStatePayload, void>(
            Request.SET_APPLICATION_STATE,
            {
                configurationState: engineControlState
            }
        );
    }

    setProductConfiguration$(
        configurationInfo: ProductConfigurationInfo
    ): Observable<ProductConfigurationInfo> {
        return this.socketIoService
            .request<
                ResolveRequest,
                ResolveResponse
            >('product', configurationInfo)
            .pipe(
                map((response) => ({
                    productId: response.productId,
                    options: response.options
                }))
            );
    }

    setActiveCamera$(cameraId: string): Observable<string> {
        return this.socketIoService
            .request<SetCameraRequestPayload, SetCameraResponsePayload>(
                'setcamera',
                {
                    cameraName: cameraId
                }
            )
            .pipe(map((response) => response.cameraName));
    }

    setActiveEnvironmentId$(
        id: string,
        environmentState?: EnvironmentLightingProfileState
    ): Observable<string> {
        // perform the environmentStateSwitch first (if it should be done) and then switch the environment.
        const environmentStateSwitch$ = environmentState
            ? this.setEnvironmentState$(environmentState)
            : of(undefined);

        return environmentStateSwitch$.pipe(
            switchMap(() =>
                this.socketIoService
                    .request<SetEnvironmentRequest, SetEnvironmentResponse>(
                        REQUEST_SET_ENVIRONMENT,
                        {
                            id
                        }
                    )
                    .pipe(map((response) => response.id))
            )
        );
    }

    setEnvironmentState$(
        state: EnvironmentLightingProfileState
    ): Observable<EnvironmentLightingProfileState | undefined> {
        return this.socketIoService
            .request<SetDaytimeRequestPayload, SetDaytimeResponsePayload>(
                REQUEST_SET_DAYTIME,
                {
                    environmentState: state,
                    jump: false
                }
            )
            .pipe(
                map((response) => {
                    if (!response.environmentState) {
                        console.warn(
                            `Backend did not return a value for applied environmentState. Assuming input state [${state}] to have been successfully set`
                        );
                    }
                    return response.environmentState || state;
                })
            );
    }

    stopHighlight$(): Observable<void> {
        return this.socketIoService
            .request(REQUEST_STOP_HIGHLIGHT, undefined)
            .pipe(map(() => undefined));
    }

    takeScreenshot$(options?: ScreenshotOptions): Observable<ScreenshotSource> {
        return this.takeScreenshotInternal$(options);
    }

    setAnimationState$(
        animationId: string,
        direction: 'START' | 'END'
    ): Observable<string> {
        const playAnimationRequestPayload: PlayAnimationRequestPayload = {
            id: animationId,
            jump: false,
            direction: direction === 'START' ? 'end' : 'start'
        };
        return this.socketIoService
            .request<
                PlayAnimationRequestPayload,
                PlayAnimationResponsePayload
            >('playanimation', playAnimationRequestPayload)
            .pipe(map((response) => response.id));
    }

    setCinematicState$(
        cinematicId: string,
        state: 'ACTIVE' | 'INACTIVE'
    ): Observable<string> {
        if (state === 'INACTIVE') {
            // stop it
            return this.socketIoService
                .request(REQUEST_STOP_CINEMATIC, undefined)
                .pipe(map(() => cinematicId));
        }
        const requestPayload: PlayCinematicRequestPayload = {
            id: cinematicId
        };
        return this.socketIoService
            .request<
                PlayCinematicRequestPayload,
                PlayCinematicResponsePayload
            >(REQUEST_PLAY_CINEMATIC, requestPayload)
            .pipe(map((response) => response.id));
    }

    setHighlightState$(
        highlightId: string,
        state: 'ACTIVE' | 'INACTIVE'
    ): Observable<string> {
        if (state === 'INACTIVE') {
            // stop it
            return this.socketIoService
                .request(REQUEST_STOP_HIGHLIGHT, undefined)
                .pipe(map(() => highlightId));
        }
        const requestPayload: PlayHighlightRequestPayload = {
            id: highlightId
        };
        return this.socketIoService
            .request<
                PlayHighlightRequestPayload,
                PlayHighlightResponsePayload
            >(REQUEST_PLAY_HIGHLIGHT, requestPayload)
            .pipe(map((response) => response.id));
    }

    stopCinematic$(): Observable<void> {
        return this.socketIoService.request(REQUEST_STOP_CINEMATIC, undefined);
    }

    captureCinematic$(cinematicId: string): Observable<CinematicCaptureInfo> {
        const requestCaptureWithCancelTeardown$ =
            new Observable<CinematicCaptureInfo>((subscriber) => {
                const captureCinematicRequest$ = this.socketIoService.request<
                    CaptureCinematicRequestPayload,
                    CaptureCinematicResponsePayload
                >(REQUEST_CAPTURE_CINEMATIC, {
                    cinematicId
                });

                // flag indicating whether we received a capture-result
                let gotCaptureResultOrError = false;

                const listenToCaptureCinematicUpdates$ = this.socketIoService
                    .subscribe<CapturedCinematicEventPayload>(
                        EVENT_CAPTURED_CINEMATIC
                    )
                    .pipe(
                        withLatestFrom(captureCinematicRequest$),
                        filter(
                            ([
                                captureCinematicEvent,
                                captureCinematicResponse
                            ]) =>
                                captureCinematicEvent.id ===
                                captureCinematicResponse.id
                        ),
                        map(([captureCinematicEvent]) => captureCinematicEvent)
                    );

                listenToCaptureCinematicUpdates$
                    .pipe(
                        map(
                            (captureCinematicResponse) =>
                                captureCinematicResponse.url ||
                                (<any>captureCinematicResponse).uRL // temp-fix for engine hickup
                        ),
                        filter(
                            (cinematicUrl): cinematicUrl is string =>
                                !!cinematicUrl
                        ),
                        map((cinematicUrl) => {
                            if (!this.config.cinematicDownloadOrigin) {
                                return cinematicUrl;
                            }
                            const parsedDownloadOriginUrl = new URL(
                                this.config.cinematicDownloadOrigin
                            );
                            let parsedUrl: URL;
                            try {
                                parsedUrl = new URL(cinematicUrl);
                                // replace origin with origin provided via config
                                parsedUrl.protocol =
                                    parsedDownloadOriginUrl.protocol;
                                parsedUrl.host = parsedDownloadOriginUrl.host;
                            } catch {
                                // failed parsing as URL - treat as relative URL
                                try {
                                    parsedUrl = new URL(
                                        cinematicUrl,
                                        this.config.cinematicDownloadOrigin
                                    );
                                } catch {
                                    // failed again, use as-is
                                    return cinematicUrl;
                                }
                            }
                            return parsedUrl.toString();
                        }),
                        map(
                            (downloadUrl): CinematicCaptureInfo => ({
                                url: downloadUrl,
                                fileId:
                                    first(
                                        last(downloadUrl.split('/'))?.split('.')
                                    ) || ''
                            })
                        ),
                        take(1),
                        tap(() => {
                            gotCaptureResultOrError = true;
                        }),
                        catchError((error) => {
                            gotCaptureResultOrError = true;
                            return throwError(error);
                        }),
                        timeout(
                            isNumber(this.config.cinematicDownloadTimeout)
                                ? this.config.cinematicDownloadTimeout
                                : CINEMATIC_DOWNLOAD_TIMEOUT_DEFAULT
                        )
                    )
                    .subscribe(subscriber);

                return () => {
                    // stop capture if we didn't receive an update yet
                    if (gotCaptureResultOrError) {
                        return;
                    }
                    this.socketIoService
                        .request<StopCaptureCinematicRequestPayload, void>(
                            REQUEST_STOP_CAPTURE_CINEMATIC,
                            {
                                cinematicId
                            }
                        )
                        .subscribe();
                };
            });

        return this.featureAvailabilityService
            .canUseCinematics$()
            .pipe(
                filter(identity),
                take(1),
                switchMapTo(requestCaptureWithCancelTeardown$),
                lazyShareReplay()
            );
    }

    setContextOptionValue$(id: string, value: string): Observable<void> {
        return this.socketIoService.request<SetContextOptionPayload, void>(
            REQUEST_SET_CONTEXT_OPTION,
            {
                id,
                value
            }
        );
    }

    /**
     * Set the watermark to be used.
     */
    setWatermark$(text: string | null): Observable<void> {
        return this.socketIoService.request<SetWatermarkRequestPayload, void>(
            REQUEST_SET_WATERMARK,
            {
                text
            }
        );
    }

    /**
     * Get a screenshot from the engine.
     *
     * @param options Optional adjustments.
     * @protected
     */
    protected takeScreenshotInternal$(
        options?: ScreenshotOptions
    ): Observable<ScreenshotSource> {
        const screenshotRequest$ = this.socketIoService.request<
            ScreenshotRequestPayload | undefined,
            ScreenshotResponsePayload
        >(
            Request.SCREENSHOT,
            options?.resolutionX && options?.resolutionY
                ? {
                      resolutionX: options.resolutionX,
                      resolutionY: options.resolutionY
                  }
                : undefined
        );

        return screenshotRequest$.pipe(
            map(({ imageData }) => {
                if (!options?.provideBlob) {
                    return {
                        source: `data:image/jpeg;base64,${imageData}`,
                        mimeType: 'image/jpeg'
                    };
                }
                // create blob
                const decodedData = window.atob(imageData);
                const uInt8Array = new Uint8Array(decodedData.length);
                for (let i = 0; i < decodedData.length; i += 1) {
                    uInt8Array[i] = decodedData.charCodeAt(i);
                }
                return {
                    source: new Blob([uInt8Array], { type: 'image/jpeg' }),
                    mimeType: 'image/jpeg'
                };
            })
        );
    }

    private ensureEndsWithSlash(httpServerUrl: string) {
        if (!httpServerUrl.endsWith('/')) {
            return `${httpServerUrl}/`;
        }
        return httpServerUrl;
    }

    private buildServerRootRelativeUrl(path: string): string {
        const engineHttpServerBase = this.engineHttpUrlSubject.value;
        if (!engineHttpServerBase) {
            throw new IllegalStateError(
                'URL for engine http-server not known yet'
            );
        }
        return `${engineHttpServerBase}${
            path.startsWith('/') ? path.substring(1) : path
        }`;
    }
}
