import { flatten, isBoolean, isNil, isString, pick, uniqBy } from 'lodash-es';
import { EMPTY, Observable, catchError, combineLatest, of, take } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { PDFRequest } from '@mhp-immersive-exp/contracts/src/pdf';
import { ConfigurationSummaryGroup } from '@mhp-immersive-exp/contracts/src/ui/summary/configuration-summary-model.interface';
import { sumUpEffectivePricingAwarePricings } from '@mhp/aml-ui-shared-components/pricing';
import {
    EffectivePricing,
    EffectivePricingAware,
    PricingType
} from '@mhp/aml-ui-shared-services';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import {
    ConfigurationPricingSummaryModel,
    ConfigurationPricingSummarySubEntry,
    ConfigurationSummaryOption
} from '@mhp/communication-models';
import {
    ErrorHandlerService,
    I18nService,
    PdfService,
    RecaptchaError,
    UrlShortenerService,
    isOptionCode
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../dealer';
import { PricingService } from '../../dealer/pricing/pricing.service';
import { AmlProductDataService } from '../../product-data/aml-product-data-service';
import { DisplayedOptionsService } from '../configuration-area/helpers/displayed-options.service';
import {
    ExtendedUiConfigurationMetaItem,
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    ExtendedUiOptionGroup
} from '../configuration-model/configuration-interfaces';
import { CpqSessionHandlerService } from '../cpq/cpq-session-handler.service';
import { FinancialServiceOfferBase } from '../financial-services/financial-services.interfaces';
import { ConfigurationFilterService } from '../search/configuration-filter.service';
import {
    isCodeAware,
    isContentAware,
    isExtendedUiOptionCode
} from '../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { DEFAULT_RENDERING_ADJUSTMENTS } from '../static-renderer/static-renderer-adjustments.constants';
import { StaticRendererService } from '../static-renderer/static-renderer.service';

const SUMMARY_FLATTEN_OPTIONS_ON_DEPTH = 1;

export interface ExtendedConfigurationPricingSummarySubEntry
    extends ConfigurationPricingSummarySubEntry {
    // optional monthly rate in case a financial-service offer exists behind the scene
    monthlyRate?: number;
}

export interface ExtendedConfigurationPricingSummaryModel
    extends ConfigurationPricingSummaryModel {
    subEntries: ExtendedConfigurationPricingSummarySubEntry[];
    pricingType: PricingType;
    // optional monthly rate in case a financial-service offer exists behind the scene
    monthlyRate?: number;
}

export interface PdfCustomizationOptions {
    title?: string;
    firstName?: string;
    lastName?: string;
    description?: string;
    comment?: string;
    dealerId?: string;
    includePricing?: boolean;
}

interface StandardFeaturesListEntry {
    NAME: string;
    CONTENT: (string | StandardFeaturesListEntry)[];
}
interface StandardFeaturesListRoot {
    [key: string]: StandardFeaturesListEntry;
}

@Injectable()
export class ConfigurationSummaryService {
    private readonly summaryModel$: Observable<
        ConfigurationSummaryGroup[] | undefined
    >;

    readonly pricingSummaryModel$: Observable<
        ExtendedConfigurationPricingSummaryModel | undefined
    >;

    constructor(
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly productDataService: AmlProductDataService,
        private readonly i18nService: I18nService,
        private readonly urlShortenerService: UrlShortenerService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly pdfService: PdfService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly configurationFilterService: ConfigurationFilterService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly displayedOptionsService: DisplayedOptionsService,
        private readonly pricingService: PricingService,
        private readonly staticRendererService: StaticRendererService,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly cpqSessionHandlerService: CpqSessionHandlerService
    ) {
        this.summaryModel$ = this.initSummaryModelLogic();
        this.pricingSummaryModel$ = this.initPricingSummaryLogic();
    }

    /**
     * Get the currently valid summary model.
     */
    getSummaryGroups$(): Observable<ConfigurationSummaryGroup[] | undefined> {
        return this.summaryModel$;
    }

    /**
     * Emit the standard-features-model valid for the currently active model and product.
     */
    @MemoizeObservable()
    getStandardFeaturesSummaryGroups$(): Observable<
        ConfigurationSummaryGroup[] | undefined
    > {
        return combineLatest([
            this.configurationSessionInfoService.getActiveModelId$(),
            this.configurationSessionInfoService.getActiveProductId$()
        ]).pipe(
            switchMap(([modelId, productId]) => {
                if (!modelId || !productId) {
                    return EMPTY;
                }
                return this.i18nService.selectTranslateObject$<StandardFeaturesListRoot>(
                    `STANDARD_LIST.${modelId}.${productId}`
                );
            }),
            map((standardFeaturesBranch) => {
                if (!standardFeaturesBranch) {
                    return undefined;
                }
                return Object.entries(standardFeaturesBranch).map((entry) => {
                    const entryId = entry[0];
                    const entryValue = entry[1];

                    const summaryGroup: ConfigurationSummaryGroup = {
                        id: entryId,
                        name: entryValue.NAME,
                        content: entryValue.CONTENT.map(
                            (featureLabelOrContent) =>
                                this.mapStandardFeaturesEntryRecurse(
                                    featureLabelOrContent
                                )
                        )
                    };
                    return summaryGroup;
                });
            })
        );
    }

    /**
     * Gets the currently valid pricing summary model.
     */
    getPricingSummaryModel$(): Observable<
        ExtendedConfigurationPricingSummaryModel | undefined
    > {
        return this.pricingSummaryModel$;
    }

    /**
     * Get the pdf datasheet for the current configuration
     * @param customizationOptions Optional customization options that can be used to
     *          add additional information to the generated pdf sheet.
     */
    downloadPdf$(
        customizationOptions?: PdfCustomizationOptions
    ): Observable<[string, string, Blob]> {
        return combineLatest([
            this.i18nService.getActiveLang$(),
            this.configurationSessionInfoService.getActiveConfigurationSessionInfo$(),
            this.configurationSessionInfoService
                .getCurrentConfigurationPublicUrl$()
                .pipe(
                    switchMap((configurationPublicUrl) => {
                        if (!configurationPublicUrl) {
                            return of(undefined);
                        }
                        return this.urlShortenerService
                            .generateShortCode$(configurationPublicUrl)
                            .pipe(catchError(() => of(undefined)));
                    })
                ),
            this.staticRendererService
                .getRenderingSrcsetWithSessionData$(
                    this.configurationSessionInfoService
                        .getActiveConfigurationSessionInfo$()
                        .pipe(map((sessionInfo) => sessionInfo?.engineData)),
                    undefined,
                    DEFAULT_RENDERING_ADJUSTMENTS
                )
                .pipe(
                    map(
                        (adjustedRenderingAndSessionData) =>
                            adjustedRenderingAndSessionData?.sessionData
                    )
                ),
            this.pricingService.getActivePricingType$(),
            this.cpqSessionHandlerService.getSessionId$()
        ]).pipe(
            take(1),
            switchMap(
                ([
                    activeLanguage,
                    sessionInfo,
                    configurationPublicUrlShortCode,
                    adjustedSessionData,
                    pricingType,
                    sessionId
                ]) => {
                    if (!sessionInfo || !adjustedSessionData) {
                        throw new IllegalStateError(
                            'No configuration session state available'
                        );
                    }

                    const serializedSessionInfo =
                        this.staticRendererService.getSerializedIodEngineSessionData(
                            adjustedSessionData
                        );
                    const { modelId } = sessionInfo;
                    const { productId } = sessionInfo;

                    const isDealerBuild =
                        environment.appConfig.dealer.dealerBuild;

                    let baseUrl = environment.appConfig.pdfWebservice.url;
                    let downloadPdfPayload: Omit<PDFRequest, 'locale'> = {
                        productId,
                        configEncoded: serializedSessionInfo,
                        countryCode: sessionInfo.country,
                        modelCode: modelId,
                        sessionId,
                        projectspecific: {
                            configCode: configurationPublicUrlShortCode
                        }
                    };

                    if (customizationOptions) {
                        downloadPdfPayload = {
                            ...downloadPdfPayload,
                            title: customizationOptions.title,
                            firstName: customizationOptions.firstName,
                            lastName: customizationOptions.lastName,
                            comment: customizationOptions.comment,
                            description: customizationOptions.description,
                            dealerCode: customizationOptions.dealerId
                        };
                    }

                    if (
                        isDealerBuild &&
                        pricingType !== 'HIDE' &&
                        (!isBoolean(customizationOptions?.includePricing) ||
                            customizationOptions?.includePricing)
                    ) {
                        downloadPdfPayload = {
                            ...downloadPdfPayload,
                            pricingRequest: {
                                useRetailPrice: pricingType === 'RRP'
                            }
                        };
                    }

                    baseUrl = `${baseUrl}/${activeLanguage}`;

                    return this.pdfService
                        .downloadPdf$(baseUrl, downloadPdfPayload)
                        .pipe(
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: (error) => {
                                    if (error instanceof RecaptchaError) {
                                        return translate(
                                            'COMMON.ERROR.RECAPTCHA_CHECK_FAILED'
                                        );
                                    }
                                    return translate(
                                        'SUMMARY.CONFIGURATION_SUMMARY.ERROR.FAILED_FETCHING_PDF'
                                    );
                                }
                            }),
                            map((blob): [string, string, Blob] => [
                                modelId,
                                productId,
                                blob
                            ])
                        );
                }
            )
        );
    }

    private initSummaryModelLogic(): Observable<
        ConfigurationSummaryGroup[] | undefined
    > {
        return combineLatest([
            this.getOptionGroupsForPricingCalculation$(),
            this.dealerHooksService
                .getFinancialServiceHooks()
                .getFinancialServicesOfferInfo$()
                .pipe(map((info) => info.offer))
        ]).pipe(
            debounceTime(0),
            map(
                ([optionGroups, financialServiceOffer]): [
                    ExtendedUiOptionGroup[] | undefined,
                    FinancialServiceOfferBase | undefined
                ] => [
                    this.filterOptionGroupsForSummaryDisplay(optionGroups),
                    financialServiceOffer
                ]
            ),
            map(([optionGroups, financialServiceOffer]) =>
                this.mapOptionGroupsToSummaryGroups(
                    optionGroups,
                    financialServiceOffer?.options
                )
            )
        );
    }

    private initPricingSummaryLogic(): Observable<
        ExtendedConfigurationPricingSummaryModel | undefined
    > {
        return combineLatest([
            this.getOptionGroupsForPricingCalculation$(),
            this.productConfigurationSessionService.getOptionGroups$(),
            this.productDataService.getActiveProductInfo$(),
            this.pricingService.getAlternatePricingData$(),
            this.pricingService.getActivePricingType$(),
            this.dealerHooksService
                .getFinancialServiceHooks()
                .getFinancialServicesOfferInfo$()
                .pipe(map((info) => info.offer)),
            this.i18nService.getActiveLang$()
        ]).pipe(
            debounceTime(0),
            this.errorHandlerService.applyRetryWithHintsOnError((error) =>
                translate('SUMMARY.PRICING.ERROR.FAILED_FETCHING_PRICING')
            ),
            map(
                ([
                    optionGroupsSelectedItemsOnly,
                    optionGroups,
                    productInfo,
                    alternatePricingData,
                    pricingType,
                    financialServiceOffer,
                    ,
                ]): ExtendedConfigurationPricingSummaryModel | undefined => {
                    if (
                        !optionGroupsSelectedItemsOnly ||
                        !optionGroups ||
                        !productInfo
                    ) {
                        return undefined;
                    }

                    const adjustedPricingType =
                        alternatePricingData?.pricingType ?? pricingType;

                    const optionGroupsToListOptionsFor =
                        this.filterOptionGroupsForOptionPricingList(
                            optionGroupsSelectedItemsOnly
                        );

                    const optionEntries: ConfigurationPricingSummarySubEntry[] =
                        optionGroupsToListOptionsFor.map((optionGroup) => {
                            const groupEffectivePricingAware =
                                this.sumUpOptionGroupContents(optionGroup);
                            return {
                                ...(groupEffectivePricingAware ?? {}),
                                id: optionGroup.id,
                                label: optionGroup.nameTranslated,
                                priceFormatted: ''
                            };
                        });

                    const serviceAndWarrantyOptions: ExtendedUiOptionCode[] =
                        this.getServiceAndWarrantyOptions(
                            optionGroupsSelectedItemsOnly
                        );
                    const serviceAndWarrantyEntries: ConfigurationPricingSummarySubEntry[] =
                        serviceAndWarrantyOptions.map((optionCode) => ({
                            id: optionCode.id,
                            label: optionCode.nameTranslated,
                            pricing: optionCode.pricing,
                            priceFormatted: ''
                        }));

                    // base-price entry
                    const effectiveLocalBasePrice =
                        adjustedPricingType === 'DNP'
                            ? productInfo?.pricing?.wholeSalePrice
                            : productInfo?.pricing?.retailPrice;

                    const effectiveBasePricing: EffectivePricing | undefined =
                        alternatePricingData?.baseModelPrice ??
                        (productInfo.pricing
                            ? {
                                  ...productInfo.pricing,
                                  price: effectiveLocalBasePrice
                              }
                            : undefined);

                    const basePriceEntry: ExtendedConfigurationPricingSummarySubEntry =
                        {
                            id: 'BASE_PRICE',
                            label: translate('SUMMARY.PRICING.BASE_PRICE'),
                            pricing: effectiveBasePricing,
                            priceFormatted: ''
                        };
                    // sum up total options price
                    const totalOptionsCurrencyAwarePricing =
                        sumUpEffectivePricingAwarePricings(
                            optionEntries,
                            this.errorHandlerService
                        );

                    // entry summing-up total options
                    const totalOptionsEntry: ConfigurationPricingSummarySubEntry =
                        {
                            ...(totalOptionsCurrencyAwarePricing ?? {}),
                            id: 'TOTAL_OPTIONS',
                            label: translate('SUMMARY.PRICING.OPTIONS'),
                            priceFormatted: ''
                        };

                    totalOptionsEntry.children = optionEntries;

                    // special entries, e.g. gas-guzzler
                    const specialEntries =
                        this.getSpecialEntries(optionGroups) ?? [];

                    const totalPriceRelevantEntries = [
                        basePriceEntry,
                        ...optionEntries,
                        ...serviceAndWarrantyEntries,
                        ...specialEntries
                    ];

                    const subEntries = [
                        basePriceEntry,
                        totalOptionsEntry,
                        ...serviceAndWarrantyEntries,
                        ...specialEntries
                    ];

                    const totalCurrencyAwarePricing =
                        sumUpEffectivePricingAwarePricings(
                            totalPriceRelevantEntries,
                            this.errorHandlerService
                        );

                    return {
                        ...(totalCurrencyAwarePricing ?? {}),
                        subEntries,
                        monthlyRate: financialServiceOffer?.monthlyRate,
                        priceFormatted: '',
                        pricingType: adjustedPricingType
                    };
                }
            ),
            lazyShareReplay()
        );
    }

    private sumUpOptionGroupContents(
        optionGroup: ExtendedUiOptionGroup
    ): EffectivePricingAware | undefined {
        return this.sumUpMetaElement(
            undefined,
            optionGroup,
            [],
            new Set<string>()
        );
    }

    private sumUpMetaElement(
        pricing: EffectivePricingAware | undefined,
        metaElement: ExtendedUiConfigurationMetaItem,
        codePath: string[],
        collectedCodes: Set<string>
    ): EffectivePricingAware | undefined {
        if (isContentAware(metaElement)) {
            const updatedCodePath = isCodeAware(metaElement)
                ? [...codePath, metaElement.code]
                : [...codePath];
            return (<ExtendedUiConfigurationMetaItem[]>(
                metaElement.content
            )).reduce(
                (prevPrice, element): EffectivePricingAware | undefined => {
                    const summedUpPricingAwareLocal = this.sumUpMetaElement(
                        undefined,
                        element,
                        updatedCodePath,
                        collectedCodes
                    );

                    const summedUpPricing = summedUpPricingAwareLocal?.pricing;
                    const summedUpPrice = summedUpPricing?.price;

                    if (isNil(summedUpPricing) || isNil(summedUpPrice)) {
                        return prevPrice;
                    }

                    const itemPrice =
                        (prevPrice?.pricing?.price ?? 0) + summedUpPrice;

                    return {
                        pricing: {
                            ...(prevPrice?.pricing ?? summedUpPricing),
                            price: itemPrice
                        }
                    };
                },
                pricing
            );
        }
        const currentCodePathString = [...codePath, metaElement.code].join('-');
        if (
            isOptionCode(metaElement) &&
            !collectedCodes.has(currentCodePathString)
        ) {
            collectedCodes.add(currentCodePathString);
            return pick(metaElement, 'pricing');
        }
        return undefined;
    }

    @MemoizeObservable()
    private getOptionGroupsForPricingCalculation$() {
        return this.productConfigurationSessionService.getOptionGroupsSelectedItemsOnly$();
    }

    private mapOptionGroupsToSummaryGroups(
        optionGroups: ExtendedUiOptionGroup[] | undefined,
        financialServiceOfferMapping: Map<string, number> | undefined
    ): ConfigurationSummaryGroup<ExtendedUiOptionCode>[] | undefined {
        if (!optionGroups) {
            return undefined;
        }

        const summaryGroups: ConfigurationSummaryGroup<ExtendedUiOptionCode>[] =
            optionGroups
                .map((currentOptionGroup) =>
                    this.mapContentAware(
                        currentOptionGroup,
                        0,
                        financialServiceOfferMapping
                    )
                )
                .filter(
                    (mappedGroup): mappedGroup is ConfigurationSummaryGroup =>
                        !!(<any>mappedGroup).content
                )
                .map((mappedGroup) => {
                    if (
                        mappedGroup.name ===
                        environment.appConfig.configuration.identifierSummary
                    ) {
                        mappedGroup.name = undefined;
                    }
                    return mappedGroup;
                });
        return summaryGroups;
    }

    private mapContentAware(
        contentAware: ExtendedUiContentAwareConfigurationMetaItem,
        depth: number,
        financialServiceOfferMapping: Map<string, number> | undefined
    ) {
        const content = flatten(
            (<any[]>contentAware.content).map(
                (
                    currentContent
                ):
                    | (ConfigurationSummaryOption | ConfigurationSummaryGroup)
                    | (
                          | ConfigurationSummaryGroup
                          | ConfigurationSummaryOption
                      )[] => {
                    if (isContentAware(currentContent)) {
                        return this.mapContentAware(
                            currentContent,
                            depth + 1,
                            financialServiceOfferMapping
                        );
                    }
                    if (isExtendedUiOptionCode(currentContent)) {
                        return this.mapOptionCode(
                            currentContent,
                            financialServiceOfferMapping
                        );
                    }
                    throw new IllegalStateError(
                        `Unexpected node type ${(<any>currentContent).type}`
                    );
                }
            )
        );

        if (!contentAware.nameTranslated) {
            return content;
        }

        const mappedGroup: ConfigurationSummaryGroup<ExtendedUiOptionCode> = {
            id: contentAware.id,
            name: contentAware.nameTranslated,
            content
        };

        if (depth >= SUMMARY_FLATTEN_OPTIONS_ON_DEPTH) {
            /*
             * flatten child-options and check whether only one option remains in the hierarchy.
             * If that's the case, skip all intermediary nodes in the hierarchy and pull up the single option to the current group.
             */
            const flattenedChildOptions = uniqBy(
                this.collectChildOptions(mappedGroup),
                'code'
            );
            if (flattenedChildOptions.length === 1) {
                return {
                    ...mappedGroup,
                    content: flattenedChildOptions
                };
            }
        }

        return mappedGroup;
    }

    private mapOptionCode(
        optionCode: ExtendedUiOptionCode,
        financialServiceOfferMapping: Map<string, number> | undefined
    ): ConfigurationSummaryOption<ExtendedUiOptionCode> {
        if (!optionCode.selected) {
            throw new IllegalStateError(
                'Non-selected options must not appear in the summary.'
            );
        }

        return {
            id: optionCode.id,
            code: optionCode.code,
            name: optionCode.nameTranslated,
            description: optionCode.descriptionTranslated,
            pricing: optionCode.pricing,
            monthlyRate: financialServiceOfferMapping?.get(
                optionCode.codeDotNotation
            ),
            node: optionCode
        };
    }

    private collectChildOptions(
        summaryGroup: ConfigurationSummaryGroup,
        options?: ConfigurationSummaryOption[]
    ): ConfigurationSummaryOption[] {
        const collectedOptions = flatten(
            summaryGroup.content.map((groupOrOption) => {
                if (this.isConfigurationSummaryGroup(groupOrOption)) {
                    return this.collectChildOptions(groupOrOption, options);
                }
                return groupOrOption;
            })
        );
        return [...(options || []), ...collectedOptions];
    }

    private isConfigurationSummaryGroup(
        candidate: ConfigurationSummaryGroup | ConfigurationSummaryOption
    ): candidate is ConfigurationSummaryGroup {
        return !!(<any>candidate).content;
    }

    private filterOptionGroupsForSummaryDisplay(
        optionGroups: ExtendedUiOptionGroup[] | undefined
    ) {
        if (!optionGroups) {
            return undefined;
        }

        return this.configurationFilterService.filterOptionGroupsUsingItemCallback(
            optionGroups,
            (node) => {
                // remove gaz-guzzler-tax
                if (isExtendedUiOptionCode(node)) {
                    return (
                        node.code !==
                        environment.appConfig.configuration
                            .identifierOptionCodeGasGuzzlerTax
                    );
                }
                // remove service and warranty groups
                if (isContentAware(node)) {
                    return ![
                        environment.appConfig.configuration
                            .identifierSummaryService,
                        environment.appConfig.configuration
                            .identifierSummaryWarranty
                    ].includes(node.name);
                }
                return true;
            }
        );
    }

    private filterOptionGroupsForOptionPricingList(
        optionGroups: ExtendedUiOptionGroup[]
    ) {
        return optionGroups.filter(
            (optionGroup) =>
                optionGroup.name !==
                environment.appConfig.configuration.identifierSummary
        );
    }

    private getSpecialEntries(
        optionGroups: ExtendedUiOptionGroup[]
    ): ConfigurationPricingSummarySubEntry[] | undefined {
        const foundNode = this.nodeLookupService.findNode(
            (node): node is ExtendedUiOptionCode =>
                isExtendedUiOptionCode(node) &&
                node.code ===
                    environment.appConfig.configuration
                        .identifierOptionCodeGasGuzzlerTax,
            optionGroups
        )?.node;

        if (!foundNode) {
            return undefined;
        }

        return [
            {
                id: foundNode.id,
                label: foundNode.nameTranslated,
                pricing: foundNode.pricing,
                priceFormatted: ''
            }
        ];
    }

    private getServiceAndWarrantyOptions(
        optionGroups: ExtendedUiOptionGroup[]
    ) {
        return flatten(
            [
                environment.appConfig.configuration.identifierSummaryService,
                environment.appConfig.configuration.identifierSummaryWarranty
            ].map((nodeName) => {
                const foundNode = this.nodeLookupService.findNode(
                    (node) => node.name === nodeName,
                    optionGroups
                )?.node;

                if (!foundNode) {
                    return [];
                }

                return this.displayedOptionsService.getOptionsContainedInBranch(
                    foundNode.id,
                    optionGroups
                );
            })
        );
    }

    private mapStandardFeaturesEntryRecurse(
        featureLabelOrContent: string | StandardFeaturesListEntry
    ): ConfigurationSummaryGroup | ConfigurationSummaryOption {
        if (isString(featureLabelOrContent)) {
            return {
                id: featureLabelOrContent,
                name: featureLabelOrContent,
                node: featureLabelOrContent,
                code: featureLabelOrContent
            };
        }
        return <ConfigurationSummaryGroup>{
            id: featureLabelOrContent.NAME,
            name: featureLabelOrContent.NAME,
            content: featureLabelOrContent.CONTENT.map(
                (featureLabelOrContentLocal) =>
                    this.mapStandardFeaturesEntryRecurse(
                        featureLabelOrContentLocal
                    )
            )
        };
    }
}
