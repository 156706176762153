import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src';
import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';
import { StreamSessionInfo } from '@mhp/ui-shared-services';

import { ProductConfigurationOptions } from '../common/configuration-interfaces';

export enum VisualizationMode {
    BASIC = 'BASIC',
    STREAM = 'STREAM'
}

export interface StageState {
    // the currently active visualization mode (when all dependencies have been fulfilled)
    activeVisualizationMode?: VisualizationMode;
    // the visualization mode that should be active (e.g. stream should be active but we're still waiting for an instance to become available)
    targetVisualizationMode?: VisualizationMode;
    // the visualization mode that may be transitioned to active visualization mode
    candidateVisualizationMode?: VisualizationMode;
    // if the candidateVisualizationMode has been approved
    candidateVisualizationModeAck?: boolean;
    // if we should try to transition silently to the targetVisualizationMode without user-interruption
    transitionSilentlyToTargetVisualizationMode?: boolean;
    // information about a possibly active streaming-session
    sessionInfo?: StreamSessionInfo;
    // if the stage should be rendered in a shrinked state
    shrinked?: boolean;
    // if the stage should be rendered in a minimized state
    minimized?: boolean;
}

export type ConfigurationNavigationArea =
    | 'cameras'
    | 'animations'
    | 'highlights'
    | 'cinematics'
    | 'environments';

export interface EnvironmentInfoShownState extends Identifiable {
    timestamp: number;
}

export interface LocalConfigurationState {
    activeNavigationArea?: ConfigurationNavigationArea | undefined;
    activeConfiguration?: ProductConfigurationOptions;
    activeSearchTerm?: string;
    searchInputActive: boolean;
    // e.g. AM6, AM8, ...
    modelId?: string;
    stageState?: StageState;
    // selected-state for horizontal configuration-bar
    categorySelection: string[];
    // a group that should get focus
    focusTargetNode?: {
        id: string;
        skipCameraChange?: boolean | undefined;
    };
    initialSummaryVisit: boolean;
    // if the info-popup for the an environment has been shown already
    environmentInfoShown: EnvironmentInfoShownState[];
    // to track whether the user has been confronted with the editions-info-dialog already
    editionsInfoShown: boolean;
    // to track whether the user actively did toggle the roof option
    userToggledRoof: boolean;
    // To determine if the user entered a configuration code
    // If the navigation is active, prohibit any other navigation to prevent 'NavigationCancel' events
    navigationToSavedConfigurationActive: boolean;
    // If there is an update of the session data currently in progress
    updateSessionDataInProgress: boolean;
    // If set, this defines the choice a user actively did in the current configuration session regarding the current EnvironmentLightingProfileState
    userActiveEnvironmentLightingStateChoice?: EnvironmentLightingProfileState;
}
