/*! npm.im/intrinsic-scale */
'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
function fit(contains) {
  return function (parentWidth, parentHeight, childWidth, childHeight) {
    var doRatio = childWidth / childHeight;
    var cRatio = parentWidth / parentHeight;
    var width = parentWidth;
    var height = parentHeight;
    if (contains ? doRatio > cRatio : doRatio < cRatio) {
      height = width / doRatio;
    } else {
      width = height * doRatio;
    }
    return {
      width: width,
      height: height,
      x: (parentWidth - width) / 2,
      y: (parentHeight - height) / 2
    };
  };
}
var contain = fit(true);
var cover = fit(false);
exports.contain = contain;
exports.cover = cover;