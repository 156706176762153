import { Observable, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { I18nService } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfigurationSessionInfoService } from '../configuration/session-info/configuration-session-info.service';

export interface DisclaimerEntry {
    generic: string;
    emissionsDe: string;
    emissionsEu: string;
    figures?: string;
}

/**
 * Disclaimer service provides access to localized versions of disclaimer texts.
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class DisclaimerService {
    constructor(
        private readonly i18nService: I18nService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {}

    /**
     * Return that matching set of disclaimers
     * @param productId - Optionally provide a dedicated productId. If not provided, it'll be tried to determine the currently active one from the application-state.
     */
    @MemoizeObservable()
    getDisclaimer$(
        productId?: string
    ): Observable<DisclaimerEntry | undefined> {
        return this.getDisclaimerInternal$(productId);
    }

    @MemoizeObservable()
    private getDisclaimerInternal$(
        productId?: string
    ): Observable<DisclaimerEntry | undefined> {
        return this.configurationSessionInfoService.getActiveProductId$().pipe(
            switchMap(
                (activeProductId): Observable<DisclaimerEntry | undefined> => {
                    const referenceProductId = productId ?? activeProductId;
                    const NO_FIGURE_AVAILABLE_PLACEHOLDER = '$';
                    return combineLatest([
                        this.i18nService.selectTranslate$(
                            'DISCLAIMER.COMMON.GENERIC'
                        ),
                        this.i18nService.selectTranslate$(
                            'DISCLAIMER.COMMON.EMISSIONS_DE'
                        ),
                        this.i18nService.selectTranslate$(
                            'DISCLAIMER.COMMON.EMISSIONS_EU'
                        ),
                        this.i18nService.selectTranslateWithFallback$(
                            `DISCLAIMER.FIG.${referenceProductId}`,
                            NO_FIGURE_AVAILABLE_PLACEHOLDER
                        )
                    ]).pipe(
                        map(
                            ([
                                generic,
                                emissionsDe,
                                emissionsEu,
                                productSpecificFigures
                            ]): DisclaimerEntry => {
                                return {
                                    generic,
                                    emissionsDe,
                                    emissionsEu,
                                    figures:
                                        productSpecificFigures ===
                                        NO_FIGURE_AVAILABLE_PLACEHOLDER
                                            ? undefined
                                            : productSpecificFigures
                                };
                            }
                        )
                    );
                }
            ),
            lazyShareReplay(),
            untilDestroyed(this)
        );
    }
}
