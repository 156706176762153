import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigOption } from '@mhp-immersive-exp/contracts/src/configuration/config-option.interface';
import { StreamSessionInfo, enhanceSelector } from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../../../state';
import {
    ConfigurationNavigationArea,
    VisualizationMode
} from '../local-configuration-state.interface';

export const selectActiveConfigurationArea = enhanceSelector<
    LocalApplicationState,
    ConfigurationNavigationArea | undefined
>(pipe(map((state) => state.configuration?.activeNavigationArea)), {
    distinctUntilChanged: true
});

export const selectActiveConfigurationOptions = enhanceSelector<
    LocalApplicationState,
    (string | ConfigOption)[] | undefined
>(pipe(map((state) => state.configuration?.activeConfiguration)), {
    distinctUntilChanged: true
});

export const selectActiveConfigurationSearchTerm = enhanceSelector<
    LocalApplicationState,
    string | undefined
>(pipe(map((state) => state.configuration?.activeSearchTerm)), {
    distinctUntilChanged: true
});

export const selectSearchInputActive = enhanceSelector<
    LocalApplicationState,
    boolean
>(
    pipe(
        map(
            (state) =>
                state.configuration && state.configuration.searchInputActive
        )
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectShowSearchResults = enhanceSelector<
    LocalApplicationState,
    boolean
>(
    pipe(
        map(
            (state) =>
                !state.configuration?.searchInputActive &&
                !!state.configuration?.activeSearchTerm
        )
    ),
    {
        distinctUntilChanged: true
    }
);

const selectStageState = (localApplicationState: LocalApplicationState) =>
    localApplicationState.configuration?.stageState;

export const selectTargetVisualizationMode = enhanceSelector<
    LocalApplicationState,
    VisualizationMode | undefined
>(
    pipe(
        map(selectStageState),
        map((state) => state?.targetVisualizationMode)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectActiveVisualizationMode = enhanceSelector<
    LocalApplicationState,
    VisualizationMode | undefined
>(
    pipe(
        map(selectStageState),
        map((state) => state?.activeVisualizationMode)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectCandidateVisualizationMode = enhanceSelector<
    LocalApplicationState,
    VisualizationMode | undefined
>(
    pipe(
        map(selectStageState),
        map((state) => state?.candidateVisualizationMode)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectCandidateVisualizationModeAck = enhanceSelector<
    LocalApplicationState,
    boolean | undefined
>(
    pipe(
        map(selectStageState),
        map((state) => state?.candidateVisualizationModeAck)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectTransitionSilentlyToTargetVisualizationMode =
    enhanceSelector<LocalApplicationState, boolean>(
        pipe(
            map(selectStageState),
            map((state) => !!state?.transitionSilentlyToTargetVisualizationMode)
        ),
        {
            distinctUntilChanged: true
        }
    );

export const selectActiveStreamSessionInfo = enhanceSelector<
    LocalApplicationState,
    StreamSessionInfo | undefined
>(
    pipe(
        map(selectStageState),
        map((state) => state?.sessionInfo)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectStageShrinkedState = enhanceSelector<
    LocalApplicationState,
    boolean
>(
    pipe(
        map(selectStageState),
        map((state) => !!state?.shrinked)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectStageMinimizedState = enhanceSelector<
    LocalApplicationState,
    boolean
>(
    pipe(
        map(selectStageState),
        map((state) => !!state?.minimized)
    ),
    {
        distinctUntilChanged: true
    }
);

export const selectActiveModelId = enhanceSelector<
    LocalApplicationState,
    string | undefined
>(pipe(map((state) => state.configuration?.modelId)), {
    distinctUntilChanged: true
});

export const selectActiveCategorySelection = enhanceSelector<
    LocalApplicationState,
    string[] | undefined
>(pipe(map((state) => state.configuration?.categorySelection)), {
    distinctUntilChanged: true
});

export const selectFocusTargetNode = enhanceSelector<
    LocalApplicationState,
    { id: string; skipCameraChange?: boolean } | undefined
>(pipe(map((state) => state.configuration?.focusTargetNode)), {
    distinctUntilChanged: true
});

export const selectNavigationToSavedConfigurationActive = enhanceSelector<
    LocalApplicationState,
    boolean
>(
    pipe(
        map(
            (state) =>
                !!state.configuration?.navigationToSavedConfigurationActive
        )
    ),
    { distinctUntilChanged: true }
);
