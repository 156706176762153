/* eslint-disable max-classes-per-file */
import * as lzstring from 'lz-string';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { CustomError, deepSortObject } from '@mhp/common';

export class SerializerError extends CustomError {
    constructor(message: string, details?: any) {
        super(message, details);
    }
}

export interface SerializerServiceConfig {
    registerDebugFunctions: boolean;
}

export const SERIALIZER_SERVICE_CONFIG_TOKEN =
    new InjectionToken<SerializerServiceConfig>(
        'Provider for SerializerServiceConfig'
    );

@Injectable({
    providedIn: 'root'
})
export class SerializerService {
    constructor(
        @Inject(SERIALIZER_SERVICE_CONFIG_TOKEN)
        @Optional()
        config?: SerializerServiceConfig
    ) {
        if (config?.registerDebugFunctions) {
            // make (de)serializing available for debug purposes
            if (window) {
                (<any>window).sessionDeserialize = (data) =>
                    this.deserializeData(data);
                (<any>window).sessionSerialize = (data) =>
                    this.serializeData(data);
                (<any>window).sessionDeserializeFromQueryString = (data) =>
                    this.deserializeData(decodeURIComponent(data));
            }
        }
    }

    /**
     * Serialize a given object to string representation.
     * @param data The data to be serialized
     * @param normalize If the data should be normalized. Defaults to true. Assumes that array-order is not of importance.
     */
    serializeData<T>(data: T, normalize = true) {
        return lzstring.compressToEncodedURIComponent(
            JSON.stringify(normalize ? deepSortObject(<any>data) : data)
        );
    }

    /**
     * Deserialize a given string representation of a previously serialized object.
     * @param serializedData The data to be deserialized
     */
    deserializeData<T>(serializedData: string): T {
        const deserializedString =
            lzstring.decompressFromEncodedURIComponent(serializedData);
        if (!deserializedString) {
            throw new SerializerError(
                `Failed deserializing data: ${serializedData}`,
                serializedData
            );
        }
        return JSON.parse(deserializedString);
    }

    /**
     * Deserialize a given string representation of a previously serialized object.
     * @param serializedData The data to be deserialized
     */
    deserializeDataDontThrowErr<T>(serializedData: string): T | undefined {
        const deserializedString =
            lzstring.decompressFromEncodedURIComponent(serializedData);
        if (!deserializedString) {
            return undefined;
        }
        try {
            return JSON.parse(deserializedString);
        } catch {
            return undefined;
        }
    }
}
