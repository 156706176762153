import { Observable, map, of, startWith, switchMap, tap } from 'rxjs';

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input
} from '@angular/core';
import {
    getPrivacyUrl,
    getTermsAndConditionUrl
} from '@mhp/aml-shared/country-mapping/legal-links';
import { CookieService } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';
import {
    DisclaimerEntry,
    DisclaimerService
} from '../../disclaimer/disclaimer.service';
import {
    RegionAndLanguageSelectionInterceptor,
    RegionAndLanguageSelectionOptions,
    RegionService
} from '../../settings/region-selector/region.service';

const BACK_TO_TOP_ELEMENT_HEIGHT = 60;

@UntilDestroy()
@Component({
    selector: 'mhp-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent extends UiBaseComponent {
    // optional productId, will fallback to active product-id per default
    @Input() productId?: string;

    @Input()
    regionAndLanguageSelectionInterceptor?: RegionAndLanguageSelectionInterceptor;

    @Input()
    regionAndLanguageSelectionOptions?: RegionAndLanguageSelectionOptions;

    @Input()
    scrollToTopReference: Window | HTMLElement = window;

    @Input()
    scrollToTopAnchor?: HTMLElement;

    @Input()
    scrollToTopOffset = 0;

    copyrightYear = new Date().getFullYear();

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly disclaimerService: DisclaimerService,
        private readonly cookiesService: CookieService,
        private readonly regionService: RegionService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {
        super();
    }

    @MemoizeObservable()
    getEmissionFiguresDisclaimer$() {
        return this.getDisclaimer$().pipe(map((entry) => entry?.figures));
    }

    @MemoizeObservable()
    getEmissionParagraphDisclaimer$() {
        return this.getDisclaimer$().pipe(map((entry) => entry?.emissionsEu));
    }

    @MemoizeObservable()
    getCountryEmissionParagraphDisclaimer$() {
        return this.getDisclaimer$().pipe(map((entry) => entry?.emissionsDe));
    }

    @MemoizeObservable()
    getGenericDisclaimer$() {
        return this.getDisclaimer$().pipe(map((entry) => entry?.generic));
    }

    @MemoizeObservable()
    getPrivacyLink$(): Observable<string> {
        return this.regionService
            .getActiveRegion$()
            .pipe(map((region) => getPrivacyUrl(region)));
    }

    @MemoizeObservable()
    getTermsAndConditionsLink$(): Observable<string> {
        return this.regionService
            .getActiveRegion$()
            .pipe(map((region) => getTermsAndConditionUrl(region)));
    }

    intentShowCookieInfo() {
        this.cookiesService.showCookiePolicy$().subscribe();
    }

    intentHandleRegionSelect() {
        this.regionService
            .requestAndApplyRegionAndLanguageSelection$(
                {
                    ...this.regionAndLanguageSelectionOptions,
                    allowClose: true
                },
                this.regionAndLanguageSelectionInterceptor
            )
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    intentBackToTop() {
        if (this.scrollToTopAnchor) {
            this.scrollToTopAnchor.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        } else {
            this.scrollToTopReference.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    @MemoizeObservable()
    getBackToTopVisibilityState$(): Observable<boolean> {
        const targetElement = this.elementRef.nativeElement;

        const intersectionEntriesFactory = (referenceHeight: number) =>
            this.observeProperty<FooterComponent, number>(
                'scrollToTopOffset',
                true
            ).pipe(
                switchMap(
                    (scrollToTopOffset) =>
                        new Observable<IntersectionObserverEntry>(
                            (subscriber) => {
                                const intersectionObserver =
                                    new IntersectionObserver(
                                        (entries) => {
                                            entries.forEach((entry) =>
                                                subscriber.next(entry)
                                            );
                                        },
                                        {
                                            threshold: [
                                                Math.max(
                                                    Math.min(
                                                        (BACK_TO_TOP_ELEMENT_HEIGHT +
                                                            scrollToTopOffset) /
                                                            referenceHeight,
                                                        1
                                                    ),
                                                    0
                                                )
                                            ]
                                        }
                                    );
                                intersectionObserver.observe(targetElement);

                                return () => {
                                    intersectionObserver.disconnect();
                                };
                            }
                        )
                )
            );

        const heightChanges$ = new Observable<number>((subscriber) => {
            const resizeObserver = new ResizeObserver(() => {
                subscriber.next(targetElement.getBoundingClientRect().height);
            });
            resizeObserver.observe(targetElement);

            return () => {
                resizeObserver.disconnect();
            };
        }).pipe(
            startWith<number>(targetElement.getBoundingClientRect().height)
        );

        return heightChanges$.pipe(
            switchMap((elementHeight) =>
                intersectionEntriesFactory(elementHeight).pipe(
                    map(
                        (intersectionObserverEntry) =>
                            intersectionObserverEntry.isIntersecting
                    )
                )
            ),
            tap(() => setTimeout(() => this.changeDetectorRef.detectChanges()))
        );
    }

    @MemoizeObservable()
    private getDisclaimer$(): Observable<DisclaimerEntry | undefined> {
        return this.getProductId$().pipe(
            switchMap((productId) =>
                this.disclaimerService.getDisclaimer$(productId)
            )
        );
    }

    @MemoizeObservable()
    private getProductId$() {
        return this.observeProperty<FooterComponent, string | undefined>(
            'productId',
            true
        ).pipe(
            switchMap((localProductId) => {
                if (localProductId) {
                    return of(localProductId);
                }
                return this.configurationSessionInfoService.getActiveProductId$();
            }),
            lazyShareReplay()
        );
    }
}
