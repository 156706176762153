/* eslint-disable no-underscore-dangle */
import { isNil } from 'lodash-es';
import {
    BehaviorSubject,
    MonoTypeOperatorFunction,
    Observable,
    Subject,
    Subscription
} from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    finalize,
    map,
    startWith,
    take,
    takeUntil
} from 'rxjs/operators';

import {
    Directive,
    OnChanges,
    OnDestroy,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { IllegalStateError } from '@mhp/common';

/**
 * Provides common base functionality for UI-Components.
 *
 * Keep in mind to call #super() when overriding OnDestroy and OnChanges yourself.
 */
@Directive()
export abstract class UiBaseComponent implements OnDestroy, OnChanges {
    private _serverCallInProgress$: Observable<boolean>;

    get serverCallInProgress$() {
        if (!this._serverCallInProgress$) {
            this._serverCallInProgress$ =
                this.serverCallInProgressSubject.asObservable();
        }
        return this._serverCallInProgress$;
    }

    private _destroy$: Observable<void>;

    get destroy$() {
        if (!this._destroy$) {
            this._destroy$ = this.destroySubject.asObservable();
        }
        return this._destroy$;
    }

    private _changesSubject: Subject<SimpleChanges>;

    private get changesSubject() {
        if (!this._changesSubject) {
            this._changesSubject = new Subject<SimpleChanges>();
        }
        return this._changesSubject;
    }

    private _destroySubject: Subject<void>;

    private get destroySubject() {
        if (!this._destroySubject) {
            this._destroySubject = new Subject<void>();
        }
        return this._destroySubject;
    }

    private _serverCallInProgressSubject: BehaviorSubject<boolean>;

    private get serverCallInProgressSubject() {
        if (!this._serverCallInProgressSubject) {
            this._serverCallInProgressSubject = new BehaviorSubject<boolean>(
                false
            );
        }
        return this._serverCallInProgressSubject;
    }

    private serverCallActiveCount = 0;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected constructor() {}

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (this._changesSubject) {
            this._changesSubject.next(simpleChanges);
        }
    }

    ngOnDestroy() {
        if (this._changesSubject) {
            this._changesSubject.complete();
        }

        try {
            if (this._destroySubject) {
                this._destroySubject.next();
                this._destroySubject.complete();
            }
        } catch (error) {
            console.warn(
                'Caught exception while notifying about destruction',
                error
            );
        }

        if (this._serverCallInProgressSubject) {
            this._serverCallInProgressSubject.complete();
        }
    }

    protected checkMandatoryInputsSet<K = unknown>(
        ...propertyNames: Extract<keyof K, string>[]
    ) {
        propertyNames.forEach((propertyName) => {
            if (isNil((<K>(<unknown>this))[propertyName])) {
                throw new IllegalStateError(
                    `Missing mandatory input property ${propertyName}`
                );
            }
        });
    }

    /**
     * Subscribe to property-changes triggered on ngOnChanges.
     * Per default, it is assumed that this method is called in ngOnInit. See #startWithCurrentValue for explanation.
     * @param propertyName
     * @param startWithCurrentValue When calling in ngOnInit, set this to true (default), when called from constructor set this to false.
     *              In case this method is called in #onInit, the first-change has already been broadcasted so
     *          as a default, upon subscription, the current value of the given property is taken and emitted.
     */
    observeChangesOfProperty<K = unknown>(
        propertyName: Extract<keyof K, string>,
        startWithCurrentValue = true
    ): Observable<SimpleChange> {
        return new Observable((subscriber) => {
            let changes$ = this.changesSubject.pipe(
                map((simpleChanges) => simpleChanges[propertyName]),
                filter((simpleChange) => !!simpleChange)
            );

            if (startWithCurrentValue) {
                changes$ = changes$.pipe(
                    filter((simpleChange) => !simpleChange.isFirstChange()),
                    startWith(
                        new SimpleChange(
                            undefined,
                            (<K>(<unknown>this))[propertyName],
                            true
                        )
                    )
                );
            }

            const subscription = changes$.subscribe(subscriber);

            return () => {
                subscription.unsubscribe();
            };
        });
    }

    /**
     * Subscribe to property-changes triggered on ngOnChanges. See #observeChangesOfProperty for detailed description.
     * @param propertyName
     * @param startWithCurrentValue
     */
    observeProperty<K, T>(
        propertyName: Extract<keyof K, string>,
        startWithCurrentValue = true
    ): Observable<T> {
        return this.observeChangesOfProperty<K>(
            propertyName,
            startWithCurrentValue
        ).pipe(
            map((simpleChange) => simpleChange.currentValue),
            distinctUntilChanged()
        );
    }

    /**
     * Unsubscribe from a given list of subscriptions upon component destruction.
     * @param subscriptions
     */
    protected unsubscribeOnDestroy(...subscriptions: Subscription[]) {
        this.destroy$.subscribe(() => {
            subscriptions.forEach((subscription) => {
                try {
                    if (!subscription.closed) {
                        subscription.unsubscribe();
                    }
                } catch {
                    console.warn(
                        `Failed unsubscribing from subscription on destroy.`,
                        subscription
                    );
                }
            });
        });
    }

    /**
     * Complete a given list of Subjects upong component destruction.
     * @param subjects
     */
    protected completeOnDestroy(...subjects: Subject<unknown>[]) {
        this.destroy$.subscribe(() => {
            subjects.forEach((subject) => {
                try {
                    if (subject) {
                        subject.complete();
                    }
                } catch (error) {
                    console.warn(
                        `Failed completing subject on destroy.`,
                        subject,
                        error
                    );
                }
            });
        });
    }

    /**
     * Closes a dialog upon component destruction.
     * @param dialog
     */
    protected closeDialogOnDestroy<T = unknown>(
        dialog: MatDialogRef<T>
    ): MatDialogRef<T> {
        const destroySubscription = this.destroySubject.subscribe(() =>
            dialog.close()
        );
        dialog
            .afterClosed()
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe(() => destroySubscription.unsubscribe());

        return dialog;
    }

    /**
     * Registers the given notification-reference with the destroy-subject so it will be dismissed
     * when the parent-component gets destroyed.
     * @param notification
     */
    protected closeNotificationOnDestroy<T = unknown>(
        notification: MatSnackBarRef<T>
    ): MatSnackBarRef<T> {
        const destroySubscription = this.destroySubject.subscribe(() =>
            notification.dismiss()
        );
        notification
            .afterDismissed()
            .pipe(take(1), takeUntil(this.destroy$))
            .subscribe(() => destroySubscription.unsubscribe());

        return notification;
    }

    /**
     * Given a source Observable that completes or errors, this function handles #serverCallInProgress
     * logic.
     * When passing a source Observable that does not complete, the finished callback will never be called,
     * so be sure, that the given Observable completes.
     * @param sourceObservable$
     */
    protected handleServerCallInProgress<T>(sourceObservable$: Observable<T>) {
        const serverCallbackFinished = this.onServerCallStart();
        const sharedObservable$ = sourceObservable$.pipe(
            finalize(serverCallbackFinished)
        );
        return sharedObservable$;
    }

    /**
     * Shortcut for piping through takeUntil(this.destroy$).
     * @deprecated Use Angulars built-in takeUntilDestroy() instead if in injection-context or @ngneat/until-destroy.
     */
    protected takeUntilDestroy<T>(): MonoTypeOperatorFunction<T> {
        return (source: Observable<T>): Observable<T> =>
            source.pipe(takeUntil(this.destroy$));
    }

    private onServerCallStart() {
        this.serverCallActiveCount += 1;
        if (this.serverCallActiveCount === 1) {
            this.serverCallInProgressSubject.next(true);
        }
        let finished = false;
        return () => {
            if (finished) {
                console.warn(
                    'Server call already finished. No need to call multiple times.'
                );
                return;
            }
            finished = true;
            this.serverCallActiveCount -= 1;
            if (this.serverCallActiveCount === 0) {
                this.serverCallInProgressSubject.next(false);
            }
        };
    }
}
