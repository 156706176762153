import { isEmpty } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    delay,
    filter,
    first,
    map,
    merge,
    of,
    shareReplay,
    skip,
    switchMap,
    take,
    takeUntil,
    tap,
    timer
} from 'rxjs';

import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    Inject,
    OnDestroy,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { Animation } from '@mhp-immersive-exp/contracts/src/animation/animation.interface';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { EngineSettingsItem } from '@mhp/aml-ui-shared-components/configuration/engine-settings';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import {
    ANIMATION_FADE_IN_OUT_EXPO,
    EASE_OUT_EXPO,
    IconSize,
    UiBaseComponent,
    UiMatDialogService,
    UiNotificationService
} from '@mhp/ui-components';
import {
    ApplicationStateService,
    ConfigurationResolveMissingAuthorizationError,
    EngineControlService,
    ErrorHandlerService,
    FeatureAvailabilityService,
    I18nService,
    MetaSource,
    ProductDataService,
    SiteMetaService,
    UiSharedStateService,
    buildOpenGraphDescriptionMetaSource,
    buildOpenGraphImageHeightMetaSource,
    buildOpenGraphImageMetaSource,
    buildOpenGraphImageWidthMetaSource,
    buildOpenGraphTitleMetaSource,
    buildTwitterCardMetaSource,
    buildTwitterDescriptionMetaSource,
    buildTwitterImageMetaSource,
    buildTwitterTitleMetaSource,
    downloadFile,
    gtmGA4Track
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { AmlBreakpoints } from '../../common/breakpoints/AmlBreakpoints';
import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../dealer';
import { DebugService } from '../../debug/debug.service';
import { DisclaimerService } from '../../disclaimer/disclaimer.service';
import { LabelHelperService } from '../../i18n/label-helper.service';
import { MenuService } from '../../menu/menu.service';
import {
    setActiveMenuScope,
    toggleMenuActive
} from '../../menu/state/actions/common-menu.actions';
import { MenuScope } from '../../menu/state/common-menu-state.interface';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { AnimationControlService } from '../animation-control/animation-control.service';
import { ConfigurationSessionImageAssetService } from '../assets/configuration-session-image-asset.service';
import { CameraControlService } from '../camera-control/camera-control.service';
import { CarFeatureControlService } from '../car-features/car-feature-control.service';
import { CinematicDialogComponent } from '../cinematic/cinematic-dialog/cinematic-dialog.component';
import { ConfigurationMenuComponent } from '../configuration-menu/configuration-menu.component';
import { StreamAvailableNotificationComponent } from '../configuration-stream/stream-available-notification/stream-available-notification.component';
import { ConfigurationSubmitService } from '../configuration-submit/configuration-submit.service';
import { EnvironmentControlService } from '../environment-control/environment-control.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import {
    setActiveNavigationArea,
    setInitialSummaryVisit,
    setStageShrinkedState
} from '../state/actions/configuration.actions';
import {
    ConfigurationNavigationArea,
    VisualizationMode
} from '../state/local-configuration-state.interface';
import {
    selectActiveConfigurationArea,
    selectStageMinimizedState,
    selectStageShrinkedState
} from '../state/selectors/configuration.selectors';
import { StaticRendererService } from '../static-renderer/static-renderer.service';
import { TurntableDialogComponent } from '../turntable-dialog/turntable-dialog.component';
import { VisualizationModeService } from '../visualization-mode/visualization-mode.service';
import { ConfigurationMainDeeplinkService } from './configuration-main-deeplink.service';
import { ConfigurationMainSupportService } from './configuration-main-support.service';

const ENGINE_SETTINGS_ASPECT_RATIO = 16 / 9;

/**
 * Actions that are available as actions located below the stage
 */
enum StageActions {
    TURNTABLE,
    DAY_NIGHT_MODE,
    ENVIRONMENTS,
    BEAUTY_SHOTS,
    RESET_CAMERA,
    SCREENSHOT,
    ANIMATIONS,
    CINEMATIC,
    ROOF_TOGGLE,
    ROOF_BLIND
}

@Component({
    selector: 'mhp-configuration-main',
    templateUrl: './configuration-main.component.html',
    styleUrls: ['./configuration-main.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ConfigurationMainSupportService,
        ConfigurationSubmitService,
        ConfigurationMainDeeplinkService
    ],
    animations: [
        trigger('deferRemoval', [
            transition('* => void', [
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        visibility: 'hidden'
                    })
                )
            ])
        ]),
        ANIMATION_FADE_IN_OUT_EXPO({
            name: 'fade'
        })
    ]
})
export class ConfigurationMainComponent
    extends UiBaseComponent
    implements OnInit, OnDestroy
{
    readonly VISUALIZATION_MODE = VisualizationMode;

    readonly IS_DEALER_BUILD = environment.appConfig.dealer.dealerBuild;

    readonly ICON_SIZE = IconSize;

    readonly STAGE_ACTIONS = StageActions;

    renderRaisedConfigurationBar = false;

    readonly menuIsExtended$ = new BehaviorSubject(false);

    readonly EnvironmentLightingProfileState = EnvironmentLightingProfileState;

    /**
     * Array of stage-actions in ascending order.
     * Actions with a higher priority should be listed first.
     * Currently, when the menu isn't expanded, only the first 3
     * available actions are shown.
     */
    private readonly stageActions: StageActions[] = [
        StageActions.TURNTABLE,
        StageActions.DAY_NIGHT_MODE,
        StageActions.ENVIRONMENTS,
        StageActions.BEAUTY_SHOTS,
        StageActions.RESET_CAMERA,
        StageActions.SCREENSHOT,
        StageActions.ANIMATIONS,
        StageActions.CINEMATIC,
        StageActions.ROOF_TOGGLE,
        StageActions.ROOF_BLIND
    ];

    constructor(
        private readonly viewContainerRef: ViewContainerRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly visualizationModeService: VisualizationModeService,
        private readonly engineControlService: EngineControlService,
        public readonly carFeatureControlService: CarFeatureControlService,
        private readonly featureAvailabilityService: FeatureAvailabilityService,
        public readonly cameraControlService: CameraControlService,
        private readonly environmentControlService: EnvironmentControlService,
        public readonly animationControlService: AnimationControlService,
        private readonly productDataService: ProductDataService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly staticRendererService: StaticRendererService,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly notificationService: UiNotificationService,
        private readonly labelHelperService: LabelHelperService,
        private readonly i18nService: I18nService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly uiSharedStateService: UiSharedStateService<AmlUiSharedState>,
        private readonly amlImageAssetService: ConfigurationSessionImageAssetService,
        public readonly configurationMainSupportService: ConfigurationMainSupportService,
        private readonly menuService: MenuService,
        private readonly dialogService: UiMatDialogService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly debugService: DebugService,
        private readonly siteMetaService: SiteMetaService,
        private readonly disclaimerService: DisclaimerService,
        private readonly router: Router,
        private readonly configurationSubmitService: ConfigurationSubmitService,
        private readonly configurationMainDeeplinkService: ConfigurationMainDeeplinkService
    ) {
        super();

        this.configurationMainSupportService.enableTracking();
    }

    ngOnInit() {
        this.initInitialShrinkStageState();
        this.initNotifiyUserAboutConfigSessionErrors();
        this.initMenuScopeLogic();
        this.initSocialSharingBindings();
        this.initResetAnimationsNavigationAreaWhenUnavailable();

        this.initSyncSessionInfoToUrl();
        this.initSetTargetVisualizationMode();
        this.initSwitchToAvailableStreamNotificationLogic();
        this.configurationMainSupportService.initHandleRoofBehaviorDependentOnActiveCategory();
        this.configurationMainDeeplinkService.handleDeeplink();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this.configurationSessionInfoService.stopSyncSessionToUrl();

        this.applicationStateService.dispatch(
            setInitialSummaryVisit({
                initialSummaryVisit: true
            })
        );
        // reset the users choice related to the environment lighting profile
        this.environmentControlService.resetUsersEnvironmentLightingProfileStateChoice();
    }

    @MemoizeObservable()
    isDebug$() {
        return this.debugService.getDebugState$();
    }

    takeScreenshot() {
        gtmGA4Track('take_screenshot_start');
        this.engineControlService
            .takeScreenshot$({
                provideBlob: true,
                filenameBase: this.labelHelperService
                    .getActiveModelAndProductName$()
                    .pipe(
                        map((modelName) =>
                            modelName ? `Your-${modelName}` : undefined
                        )
                    )
            })
            .pipe(
                this.errorHandlerService.applyRetryWithHintsOnError(
                    (error) => 'Failed taking screenshot.'
                )
            )
            .subscribe(
                (screenshotInfo) => {
                    if (screenshotInfo.blob) {
                        downloadFile(
                            screenshotInfo.blob,
                            screenshotInfo.filename
                        );

                        gtmGA4Track('take_screenshot_success');
                    }
                },
                () => gtmGA4Track('take_screenshot_failed')
            );
    }

    onSelectedAreaChange(selectedArea: string | 'SUMMARY') {
        switch (selectedArea) {
            case `${environment.appConfig.configuration.identifierAccessories}`:
            case `${environment.appConfig.configuration.identifierPersonalisation}`:
                this.renderRaisedConfigurationBar = true;
                break;
            case `${environment.appConfig.configuration.identifierSummary}`:
                this.renderRaisedConfigurationBar = true;
                break;
            case `${environment.appConfig.configuration.identifierInteriorWithEnvironmentSelection}`:
                this.renderRaisedConfigurationBar = false;
                break;
            default:
                this.renderRaisedConfigurationBar = false;
        }

        if (selectedArea === 'SUMMARY') {
            // see configuration-main-support.service.ts for tracking of "regular" top-level entries
            gtmGA4Track('page_menu_click', {
                page_menu: selectedArea,
                page_submenu: undefined,
                variant_category: undefined
            });
            gtmGA4Track('open_summary_click');
        }

        this.updateUiState((uiState) => {
            uiState.renderRaisedConfigurationBar =
                this.renderRaisedConfigurationBar;
            uiState.activeTopLevelCategory = selectedArea;
            return uiState;
        });
    }

    onSelectedCategoryPathChange(categoryPath: string[] | undefined) {
        this.updateUiState((uiState) => {
            uiState.activeCategoryPath = categoryPath || [];
            return uiState;
        });
    }

    @MemoizeObservable()
    getCameras$(): Observable<EngineSettingsItem[] | undefined> {
        return combineLatest([
            this.productDataService.getAvailableCameras$().pipe(
                switchMap((availableCameras) => {
                    if (!availableCameras) {
                        return of(undefined);
                    }
                    return combineLatest(
                        availableCameras?.cameras.map((camera) =>
                            this.amlImageAssetService
                                .getCameraThumbnail$(camera.id, {
                                    alternativeAspectRatio:
                                        ENGINE_SETTINGS_ASPECT_RATIO
                                })
                                .pipe(
                                    map((thumbnailSrcset) => ({
                                        id: camera.id,
                                        thumbnailSrcset
                                    }))
                                )
                        )
                    );
                })
            ),
            this.engineControlService.getActiveCamera$(),
            this.featureAvailabilityService.canAlterCamera$()
        ]).pipe(
            map(([cameras, activeCamera, canAlter]) =>
                cameras?.map((camera) => ({
                    id: camera.id,
                    imageUrl: camera.thumbnailSrcset ?? '',
                    title: camera.id,
                    selected: camera.id === activeCamera,
                    imageAspectRatio: ENGINE_SETTINGS_ASPECT_RATIO,
                    disabled: !canAlter
                }))
            ),
            this.takeUntilDestroy(),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getEnvironments$(): Observable<EngineSettingsItem[] | undefined> {
        return combineLatest([
            this.productDataService.getAvailableEnvironments$().pipe(
                switchMap((availableEnvironments) => {
                    if (!availableEnvironments) {
                        return of(undefined);
                    }
                    return combineLatest(
                        availableEnvironments?.map((currentEnvironment) =>
                            this.amlImageAssetService
                                .getEnvironmentThumbnail$(currentEnvironment, {
                                    alternativeAspectRatio:
                                        ENGINE_SETTINGS_ASPECT_RATIO
                                })
                                .pipe(
                                    map((thumbnailSrcset) => ({
                                        id: currentEnvironment.id,
                                        thumbnailSrcset
                                    }))
                                )
                        )
                    );
                })
            ),
            this.engineControlService.getActiveEnvironmentId$()
        ]).pipe(
            map(([environments, activeEnvironmentId]) =>
                environments?.map(
                    (environmentLocal): EngineSettingsItem => ({
                        id: environmentLocal.id,
                        imageUrl: environmentLocal.thumbnailSrcset ?? '',
                        title: environmentLocal.id,
                        selected: environmentLocal.id === activeEnvironmentId,
                        hasInfoContent:
                            this.configurationMainSupportService.hasEnvironmentInfo(
                                environmentLocal.id
                            ),
                        imageAspectRatio: ENGINE_SETTINGS_ASPECT_RATIO
                    })
                )
            ),
            this.takeUntilDestroy(),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getAnimations$(): Observable<EngineSettingsItem[] | undefined> {
        if (!environment.appConfig.featureToggles.configurationAnimations) {
            return of(undefined);
        }

        return combineLatest([
            this.getAvailableAnimations$().pipe(
                switchMap((availableAnimations) => {
                    if (!availableAnimations) {
                        return of(undefined);
                    }
                    return combineLatest(
                        availableAnimations?.map((animation) =>
                            this.amlImageAssetService
                                .getAnimationThumbnail$(animation, {
                                    alternativeAspectRatio:
                                        ENGINE_SETTINGS_ASPECT_RATIO
                                })
                                .pipe(
                                    map((thumbnailSrcset) => ({
                                        id: animation.id,
                                        thumbnailSrcset
                                    }))
                                )
                        )
                    );
                })
            ),
            this.engineControlService.getAnimationStates$()
        ]).pipe(
            map(([animations, animationStates]) =>
                animations?.map((animationLocal) => ({
                    id: animationLocal.id,
                    imageUrl: animationLocal.thumbnailSrcset ?? '',
                    title: animationLocal.id,
                    selected: !!animationStates.find(
                        (animationState) =>
                            animationState.id === animationLocal.id &&
                            animationState.state === 'END'
                    ),
                    imageAspectRatio: ENGINE_SETTINGS_ASPECT_RATIO
                }))
            ),
            this.takeUntilDestroy(),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getAvailableAnimations$(): Observable<Animation[] | undefined> {
        if (!environment.appConfig.featureToggles.configurationAnimations) {
            return of(undefined);
        }

        return this.productDataService
            .getAvailableAnimations$()
            .pipe(
                this.visualizationModeService.filterAvailableEntities(),
                lazyShareReplay()
            );
    }

    @MemoizeObservable()
    isAnimationsAvailable$() {
        return this.getAvailableAnimations$().pipe(
            map(
                (availableAnimations) =>
                    !!availableAnimations && availableAnimations.length > 0
            )
        );
    }

    @MemoizeObservable()
    getDealerHookOne2OneSessionControl$() {
        return this.dealerHooksService.getConfigurationMainOne2OneSessionControl$();
    }

    @MemoizeObservable()
    getActiveVisualizationMode$(): Observable<VisualizationMode | undefined> {
        return this.visualizationModeService.getActiveVisualizationMode$();
    }

    intentToggleActiveConfigurationNavigationArea(
        navigationArea: ConfigurationNavigationArea
    ) {
        if (navigationArea === 'cameras') gtmGA4Track('beauty_shot_click');
        this.getActiveConfigurationNavigationArea$()
            .pipe(
                first(),
                map((activeArea) => {
                    if (activeArea === navigationArea) {
                        return undefined;
                    }
                    return navigationArea;
                })
            )
            .subscribe((targetArea) => {
                this.applicationStateService.dispatch(
                    setActiveNavigationArea({
                        id: targetArea
                    })
                );
            });
    }

    intentSelectCamera(cameraId: string) {
        this.cameraControlService
            .setActiveCamera$(cameraId)
            .pipe(
                this.errorHandlerService.applyRetryWithHintsOnError(() =>
                    translate('CONFIGURATOR.FEATURES.CAMERAS.ERRORS.SET_CAMERA')
                ),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    intentSelectEnvironments(envId: string) {
        // track GA-event
        gtmGA4Track('environment_click', {
            active_environment: envId
        });
        gtmGA4Track('environment_click_sequence');

        this.environmentControlService.setEnvironment$(envId).subscribe();
    }

    intentShowEnvironmentInfo(envId: string) {
        this.configurationMainSupportService
            .showEnvironmentInfo(envId)
            .pipe(this.takeUntilDestroy())
            .subscribe(() => gtmGA4Track('show_environment_info_click'));
    }

    /**
     * Toggles the animation-state for the given animation.
     * @param animationId The animations ID
     */
    intentToggleAnimation(animationId: string) {
        this.animationControlService.toggleAnimation(animationId);
    }

    intentResetCamera() {
        gtmGA4Track('reset_camera_click');
        this.cameraControlService
            .intentResetCamera$()
            .pipe(
                this.errorHandlerService.applyRetryWithHintsOnError((error) =>
                    translate(
                        'CONFIGURATOR.FEATURES.CAMERAS.ERRORS.RESET_CAMERA'
                    )
                ),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    intentToggleTurntable() {
        gtmGA4Track('threesixty_view_click', { threesixty_view: 'active' });
        this.dialogService
            .openFullscreen$(TurntableDialogComponent)
            .pipe(
                this.takeUntilDestroy(),
                takeUntil(
                    this.visualizationModeService
                        .getActiveVisualizationMode$()
                        .pipe(
                            filter(
                                (visMode) =>
                                    visMode === VisualizationMode.STREAM
                            )
                        )
                ),
                tap((dialogRef) => {
                    dialogRef
                        .afterClosed()
                        .pipe(
                            tap(() => {
                                gtmGA4Track('threesixty_view_click', {
                                    threesixty_view: 'inactive'
                                });
                            })
                        )
                        .subscribe();
                })
            )
            .subscribe();
    }

    intentToggleExpandState() {
        this.applicationStateService
            .getLocalState()
            .pipe(selectStageShrinkedState, first())
            .subscribe((isCollapsed) => {
                gtmGA4Track('bar_size_click', {
                    bar_size: isCollapsed ? 'hide' : 'show'
                });

                this.applicationStateService.dispatch(
                    setStageShrinkedState({
                        shrinked: !isCollapsed
                    })
                );
            });
    }

    intentToggleDayNightState() {
        this.environmentControlService
            .toggleEnvironmentMode$({
                userAction: true
            })
            .subscribe((newMode) =>
                gtmGA4Track('day_night_mode_click', {
                    day_night_mode:
                        newMode === EnvironmentLightingProfileState.NIGHT
                            ? 'night'
                            : 'day'
                })
            );
    }

    intentToggleRoofState() {
        gtmGA4Track('toogle_roof_click');
        this.carFeatureControlService.toggleRoofState$().subscribe();
    }

    intentToggleRoofBlindState() {
        gtmGA4Track('toogle_roof_blind_click');
        this.carFeatureControlService.toggleRoofBlindState$().subscribe();
    }

    intentToggleMenu() {
        this.applicationStateService.dispatch(toggleMenuActive());
    }

    intentSubmitToDealer() {
        this.configurationSubmitService.intentSubmitConfiguration();
    }

    intentStartCinematic() {
        this.visualizationModeService
            .getActiveVisualizationMode$()
            .pipe(
                tap((visMode) => {
                    if (visMode !== VisualizationMode.STREAM) {
                        throw new IllegalStateError(
                            'Cannot start stream when not in streaming-mode'
                        );
                    }
                }),
                take(1),
                switchMap(() =>
                    this.dialogService
                        .openFullscreen$(CinematicDialogComponent, {
                            componentFactoryResolver:
                                this.componentFactoryResolver,
                            viewContainerRef: this.viewContainerRef
                        })
                        .pipe(
                            switchMap((dialogRef) => {
                                gtmGA4Track('cinematic_video_start');

                                return this.engineControlService
                                    .toggleCinematicState(
                                        environment.appConfig.visualization
                                            .defaultCinematic
                                    )
                                    .pipe(
                                        this.errorHandlerService.applyRetryWithHintsOnError(
                                            () =>
                                                translate(
                                                    'CONFIGURATOR.FEATURES.CINEMATICS.ERRORS.TOGGLE_CINEMATIC'
                                                )
                                        ),
                                        takeUntil(dialogRef.afterClosed())
                                    );
                            }),
                            // when cinematic stops, close dialog
                            takeUntil(
                                merge(
                                    this.engineControlService
                                        .getActiveCinematic$()
                                        .pipe(
                                            // skip current state which might be undefined
                                            skip(1),
                                            // emit when no cinematic is set
                                            filter((cinematic) => !cinematic)
                                        ),
                                    this.visualizationModeService
                                        .getActiveVisualizationMode$()
                                        .pipe(
                                            filter(
                                                (mode) =>
                                                    mode !==
                                                    VisualizationMode.STREAM
                                            )
                                        )
                                )
                            )
                        )
                ),
                this.takeUntilDestroy()
            )
            .subscribe({
                complete: () => {
                    this.engineControlService
                        .stopCinematic()
                        .pipe(
                            this.errorHandlerService.applyRetryWithHintsOnError(
                                () =>
                                    translate(
                                        'CONFIGURATOR.FEATURES.CINEMATICS.ERRORS.TOGGLE_CINEMATIC'
                                    )
                            )
                        )
                        .subscribe(() =>
                            gtmGA4Track('cinematic_video_complete')
                        );
                }
            });
    }

    intentShowFinancialServiceOptions() {
        if (!environment.appConfig.featureToggles.enableFinancialServices) {
            return;
        }
        this.configurationSessionInfoService
            .getActiveProductId$()
            .pipe(
                filter((productId) => !!productId),
                take(1),
                switchMap(() =>
                    this.dealerHooksService
                        .getFinancialServiceHooks()
                        .showFinancialServicesOptions$({
                            viewContainerRef: this.viewContainerRef
                        })
                ),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    intentToggleActionsMenu() {
        gtmGA4Track('actions_menu_toggle_click', {
            actions_menu_status: this.menuIsExtended$.value ? 'open' : 'close'
        });

        this.menuIsExtended$.next(!this.menuIsExtended$.value);
    }

    onRevealNestedViewChange(shouldReveal: boolean) {
        if (!shouldReveal) {
            // force disable camera/environment/animation navigation-area
            this.getActiveConfigurationNavigationArea$()
                .pipe(
                    first(),
                    map((activeArea) => {
                        if (
                            activeArea === 'environments' ||
                            activeArea === 'cameras' ||
                            activeArea === 'animations'
                        ) {
                            return undefined;
                        }
                        return activeArea;
                    })
                )
                .subscribe((targetArea) => {
                    this.applicationStateService.dispatch(
                        setActiveNavigationArea({
                            id: targetArea
                        })
                    );
                });
        }
    }

    @MemoizeObservable()
    getActiveConfigurationNavigationArea$(): Observable<
        ConfigurationNavigationArea | undefined
    > {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveConfigurationArea, delay(0), lazyShareReplay());
    }

    @MemoizeObservable()
    disableDeferredRemovalAnimation$() {
        return this.applicationStateService.getLocalState().pipe(
            selectActiveConfigurationArea,
            map((area) => !!area)
        );
    }

    @MemoizeObservable()
    getActiveInnerStageNavigationArea$(): Observable<
        'cameras' | 'environments' | 'animations' | undefined
    > {
        return combineLatest([
            this.useInnerStageNavigationArea$(),
            this.getActiveConfigurationNavigationArea$()
        ]).pipe(
            map(([useMobileNavigationArea, activeNavigationArea]) => {
                if (
                    !useMobileNavigationArea ||
                    (activeNavigationArea !== 'cameras' &&
                        activeNavigationArea !== 'environments' &&
                        activeNavigationArea !== 'animations')
                ) {
                    return undefined;
                }
                return activeNavigationArea;
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    useInnerStageNavigationArea$(): Observable<boolean> {
        return combineLatest([
            this.breakpointObserver.observe([
                AmlBreakpoints.HandsetLandscapeStageFullscreen,
                Breakpoints.TabletPortrait,
                Breakpoints.WebPortrait
            ]),
            this.isStageShrinked$()
        ]).pipe(
            map(
                ([breakpointState, stageShrinked]) =>
                    breakpointState.matches || !stageShrinked
            ),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getConfigurationBarDisplayMode$(): Observable<'COMPACT' | 'LARGE'> {
        return this.configurationMainSupportService.getConfigurationBarDisplayMode$();
    }

    @MemoizeObservable()
    isStageShrinked$(): Observable<boolean> {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectStageShrinkedState);
    }

    @MemoizeObservable()
    isStageMinimized$(): Observable<boolean> {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectStageMinimizedState);
    }

    isFinancialServiceOptionAvailable$() {
        return this.dealerHooksService
            .getFinancialServiceHooks()
            .isFinancialServiceOptionAvailable$();
    }

    @MemoizeObservable()
    isSubmitToDealerVisible$() {
        if (this.IS_DEALER_BUILD) {
            return of(false);
        }

        return this.uiSharedStateService
            .getState$()
            .pipe(
                map(
                    (state) =>
                        state?.activeTopLevelCategory !==
                        environment.appConfig.configuration.identifierSummary
                )
            );
    }

    @MemoizeObservable()
    isHandsetPortrait$() {
        return this.breakpointObserver
            .observe(Breakpoints.HandsetPortrait)
            .pipe(map((state) => state.matches));
    }

    @MemoizeObservable()
    isPortraitOrientation$() {
        return this.breakpointObserver
            .observe(AmlBreakpoints.Portrait)
            .pipe(map((state) => state.matches));
    }

    @MemoizeObservable()
    getActiveDayNightState$(): Observable<
        EnvironmentLightingProfileState | undefined
    > {
        return this.environmentControlService.getActiveDayNightState$();
    }

    @MemoizeObservable()
    getActiveRoofBlindState$(): Observable<'OPEN' | 'CLOSED' | undefined> {
        return combineLatest([
            this.configurationMainSupportService.getActiveTopLevelCategory$(),
            this.engineControlService.getActiveCameraType$(),
            this.carFeatureControlService.getRoofBlindState$()
        ]).pipe(
            map(
                ([
                    activeTopLevelCategory,
                    activeCameraType,
                    roofBlindState
                ]) => {
                    if (
                        activeTopLevelCategory ===
                            environment.appConfig.configuration
                                .identifierInterior ||
                        activeTopLevelCategory ===
                            environment.appConfig.configuration
                                .identifierInteriorOptions ||
                        activeCameraType === 'Int' ||
                        activeCameraType === 'IntVR'
                    ) {
                        return roofBlindState;
                    }
                    return undefined;
                }
            )
        );
    }

    @MemoizeObservable()
    isInStreamMode$(): Observable<boolean> {
        return this.visualizationModeService
            .getActiveVisualizationMode$()
            .pipe(map((activeMode) => activeMode === VisualizationMode.STREAM));
    }

    @MemoizeObservable()
    getStageActions$(): Observable<StageActions[]> {
        return combineLatest([
            this.isInStreamMode$(),
            this.menuIsExtended$,
            this.cameraControlService.getActiveCameraType$(),
            this.getActiveDayNightState$(),
            this.isAnimationsAvailable$(),
            this.carFeatureControlService.getRoofState$(),
            this.getActiveRoofBlindState$()
        ]).pipe(
            map(
                ([
                    isStreamActive,
                    isMenuExtended,
                    cameraType,
                    dayNightState,
                    isAnimationAvailable,
                    roofToggleState,
                    roofBlindState
                ]) => {
                    const relevantStageActions = this.stageActions.filter(
                        (stageAction) => {
                            switch (stageAction) {
                                case StageActions.TURNTABLE:
                                    return (
                                        cameraType === 'Ext' && !isStreamActive
                                    );
                                case StageActions.DAY_NIGHT_MODE:
                                    return !!dayNightState;
                                case StageActions.ANIMATIONS:
                                    return isAnimationAvailable;
                                case StageActions.CINEMATIC:
                                    return isStreamActive;
                                case StageActions.ROOF_TOGGLE:
                                    return !!roofToggleState;
                                case StageActions.ROOF_BLIND:
                                    return !!roofBlindState;
                                default:
                                    return true;
                            }
                        }
                    );
                    return isMenuExtended
                        ? relevantStageActions
                        : relevantStageActions.slice(0, 3);
                }
            )
        );
    }

    private initMenuScopeLogic() {
        this.applicationStateService.dispatch(
            setActiveMenuScope({
                activeMenuScope: MenuScope.CONFIGURATION
            })
        );
        const unregisterViewContainerRefProvider =
            this.menuService.registerViewContainerRefProvider({
                handles: (scope: MenuScope): boolean =>
                    scope === MenuScope.CONFIGURATION,
                getMenuComponent: () => ConfigurationMenuComponent,
                getViewContainerRef: () => this.viewContainerRef,
                getComponentFactoryResolver: () =>
                    this.componentFactoryResolver,
                getCleanup$: () => this.destroy$
            });
        this.destroy$.subscribe(() => unregisterViewContainerRefProvider());
    }

    private initSocialSharingBindings() {
        new Observable((subscriber) => {
            const socialTitle$ = combineLatest([
                this.labelHelperService.getActiveModelAndProductName$(),
                this.i18nService.getActiveLang$()
            ]).pipe(
                map(([productName]) =>
                    translate<string>('SOCIAL_SHARING.CONFIGURATOR.HEADLINE', {
                        productName
                    })
                )
            );
            const socialDescription$ = this.i18nService.selectTranslate$(
                'SOCIAL_SHARING.CONFIGURATOR.DESCRIPTION'
            );

            const activeImage$ = this.staticRendererService
                .getActiveSessionRendering$(2048)
                .pipe(
                    map((imageUrl) => imageUrl ?? ''),
                    lazyShareReplay()
                );

            const metaTagSources: MetaSource[] = [
                buildOpenGraphDescriptionMetaSource(socialDescription$),
                buildOpenGraphTitleMetaSource(socialTitle$),
                buildOpenGraphImageMetaSource(activeImage$),
                buildOpenGraphImageWidthMetaSource(of('2048')),
                buildOpenGraphImageHeightMetaSource(of('1080')),

                buildTwitterTitleMetaSource(socialTitle$),
                buildTwitterDescriptionMetaSource(socialDescription$),
                buildTwitterCardMetaSource(of('summary_large_image')),
                buildTwitterImageMetaSource(activeImage$)
            ];

            return this.siteMetaService.pushSiteMetaProvider({
                getMetaTagSources: () => metaTagSources
            });
        })
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }

    private initNotifiyUserAboutConfigSessionErrors() {
        this.productConfigurationSessionService
            .getOptionGroupsUpdateErrors$()
            .pipe(this.takeUntilDestroy())
            .subscribe((error) => {
                let dropUserToRoot = false;
                let userMessage = translate<string>(
                    'CONFIGURATOR.ERRORS.UPDATE_METADATA'
                );

                if (
                    error instanceof
                    ConfigurationResolveMissingAuthorizationError
                ) {
                    dropUserToRoot = true;
                    userMessage = translate(
                        'CONFIGURATOR.ERRORS.UPDATE_METADATA_MISSING_AUTHORIZATION'
                    );
                }

                this.errorHandlerService.showErrorMessage(
                    () => userMessage,
                    error
                );

                if (dropUserToRoot) {
                    this.router.navigateByUrl('/');
                }
            });
    }

    private initInitialShrinkStageState() {
        this.breakpointObserver
            .observe([
                Breakpoints.HandsetPortrait,
                Breakpoints.TabletPortrait,
                Breakpoints.WebPortrait
            ])
            .pipe(first(), this.takeUntilDestroy())
            .subscribe((state) => {
                this.applicationStateService.dispatch(
                    setStageShrinkedState({
                        shrinked: !state.matches
                    })
                );
            });
    }

    /**
     * Reset animations-navigation area when active in case there are no animations available.
     * @private
     */
    private initResetAnimationsNavigationAreaWhenUnavailable() {
        combineLatest([
            this.getActiveConfigurationNavigationArea$(),
            this.getAvailableAnimations$()
        ])
            .pipe(this.takeUntilDestroy())
            .subscribe(([activeNavigationArea, availableAnimations]) => {
                if (
                    activeNavigationArea !== 'animations' ||
                    !isEmpty(availableAnimations)
                ) {
                    return;
                }
                this.applicationStateService.dispatch(
                    setActiveNavigationArea({
                        id: undefined
                    })
                );
            });
    }

    private updateUiState(
        callback: (uiState: AmlUiSharedState) => AmlUiSharedState
    ) {
        this.uiSharedStateService
            .updateUiState(callback)
            .pipe(
                this.errorHandlerService.applyRetry({
                    isEligibleForRetry: () => true,
                    messageProviderOnFinalError: () =>
                        translate('CONFIGURATOR.ERRORS.BROADCAST_SHARED_STATE')
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    private initSyncSessionInfoToUrl() {
        this.configurationSessionInfoService.startSyncSessionToUrl();
    }

    private initSetTargetVisualizationMode() {
        this.visualizationModeService
            .determineVisualizationMode$()
            .pipe(this.takeUntilDestroy())
            .subscribe((visualizationMode) => {
                this.visualizationModeService.setTargetVisualizationMode(
                    visualizationMode
                );
            });
    }

    private initSwitchToAvailableStreamNotificationLogic() {
        this.visualizationModeService
            .getCandidateVisualizationMode$()
            .pipe(
                switchMap((candidateMode) => {
                    if (candidateMode !== VisualizationMode.STREAM) {
                        return of(undefined);
                    }

                    return new Observable<
                        MatSnackBarRef<StreamAvailableNotificationComponent>
                    >((subscriber) => {
                        const cleanupSubject = new Subject<void>();

                        this.notificationService
                            .showCustomNotification$(
                                StreamAvailableNotificationComponent,
                                {
                                    data: {
                                        message: translate(
                                            'STREAMING.SWITCH_TO_STREAM'
                                        ),
                                        progress$: timer(0, 1000).pipe(
                                            take(11),
                                            takeUntil(cleanupSubject),
                                            map((progress) => progress / 10),
                                            shareReplay()
                                        )
                                    },
                                    panelClass:
                                        'mat-mdc-snack-bar-container--no-padding',
                                    horizontalPosition: 'right',
                                    verticalPosition: 'top'
                                }
                            )
                            .subscribe(subscriber);

                        return () => {
                            cleanupSubject.next();
                            cleanupSubject.complete();
                        };
                    });
                }),
                switchMap((snackBarRef) =>
                    snackBarRef
                        ? merge(
                              snackBarRef.onAction().pipe(map(() => true)),
                              snackBarRef
                                  .afterDismissed()
                                  .pipe(map(() => false))
                          ).pipe(take(1))
                        : of(undefined)
                ),
                this.takeUntilDestroy()
            )
            .subscribe((accepted) => {
                this.visualizationModeService.setCandidateVisualizationModeAck(
                    accepted
                );
            });
    }
}
